const SingleExternalPin = (props: any) => (
    <svg width="18" height="18" style={{marginTop: '6px', marginLeft: '3px'}} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3068_2377)">
            <path
                d="M10.6184 1.2996C10.9865 0.931424 11.5673 0.91726 11.9179 1.2679L16.9893 6.33932C17.34 6.68996 17.3258 7.2707 16.9576 7.63887C16.5895 8.00705 16.0087 8.02121 15.6581 7.67057L15.0737 7.08617L12.2169 10.3947C12.53 11.5356 12.4118 12.8028 11.8219 13.9394L11.7794 14.0216C11.6409 14.2909 11.3846 14.4797 11.0931 14.5335C10.8016 14.5873 10.5055 14.4992 10.3014 14.2951L3.96213 7.95584C3.75809 7.75179 3.66983 7.45969 3.72372 7.16412C3.7776 6.86855 3.96847 6.61429 4.23561 6.47789L4.31783 6.4353C5.45444 5.84545 6.7216 5.72728 7.86257 6.04039L11.1711 3.18355L10.5867 2.59915C10.236 2.24851 10.2502 1.66777 10.6184 1.2996ZM5.83222 11.1572L7.10008 12.425L5.1032 14.4219C4.73503 14.7901 4.15429 14.8042 3.80365 14.4536C3.45301 14.103 3.46718 13.5222 3.83535 13.154L5.83222 11.1572Z"
                fill="#FF5900"
            />
        </g>
        <defs>
            <clipPath id="clip0_3068_2377">
                <rect width="10.7581" height="15.0614" fill="white" transform="translate(10.6504) rotate(45)" />
            </clipPath>
        </defs>
    </svg>
)
export default SingleExternalPin
