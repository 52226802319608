import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
    Box,
    Container,
    Paper,
    MenuItem,
    Grid,
    Checkbox,
    FormControlLabel,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    FormHelperText,
    Stack,
    Chip,
    Link,
} from '@mui/material'
import ComponentStepper from '../components/Stepper'
import SelectCustom from 'src/components/SelectCustom'
import { CANDIDATE_TIME_SESSION_KEY, CONSTANTS, CandidateTime } from 'src/config/constants'
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import adjustmentApi from 'src/api/adjustmentApi'
import scheduleApi from 'src/api/scheduleApi'
import { debounce } from 'lodash'
import { limitCharacter } from 'src/helpers/string'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import LightFieldByPoint from 'src/components/Common/LightFieldPoint'
import ButtonCustom from 'src/components/Common/ButtonCustom'
import { labelOfInput, tableRowAlign, labelOfInputAlign, tableBodyText, inputField, tableHeaderText } from 'src/theme'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import * as yup from 'yup'
import moment from 'moment'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LinearProgressWithLabel from 'src/components/Common/LinearProgressWithLabel'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Scrollbar } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import { ExternalLinkIcon } from '../../../components/Icons'
import { getSessionCandidateTimeSelected, replaceGaroonName } from '../../../helpers/helpers'
import useWindowDimensions from 'src/hook/useWindowDimensions'
import { selectCurrentUser } from 'src/pages/Auth/authSlice'
import { useAppSelector } from 'src/app/hooks'
import TableWithStickyHeader from 'src/components/Common/TableWithStickyHeader'
import SingleExternalUserModal from '../components/SingleExternalUserModal'
import { formatDateTimeWithDayOfWeek, groupByDate, groupByDateForEditting } from 'src/helpers/formatDate'

const AdjustmentSingleExternalStep2 = () => {
    const { sid } = useParams()
    const navigate = useNavigate()
    const [filter, setFilter] = useState<any>({
        timeFrame: 30,
        periodOfTime: [dayjs(new Date()).hour(10).minute(0), dayjs(new Date()).hour(19).minute(0)],
        scheduleDuration: 30,
        marginTime: 15,
        isHideXUser: false,
        isHideHoliday: true,
    })
    const [checkBox, setCheckBox] = useState<boolean[]>([])
    const [loadedTime, setLoadedTime] = useState<string>('')
    const [garoonLink, setGaroonLink] = useState<string>('')
    const [participant, setParticipant] = useState([])
    const [page, setPage] = useState(1)
    const [rows, setRows] = useState<any>([])
    const [rowsGroupByDate, setRowsGroupByDate] = useState<any>([])
    const [rowsGroupByDateForEditting, setRowsGroupByDateForEditting] = useState<any>([])
    const { showSnackBar } = useSnackBar()
    const [checkAvailableData, setCheckAvailableData] = useState(false)
    const [progress, setProgress] = useState(0);
    const currentUser = useAppSelector(selectCurrentUser)
    const [openStartTime, setOpenStartTime] = useState(false)
    const [openEndTime, setOpenEndTime] = useState(false)
    const { height, width } = useWindowDimensions();
    const [scheduleTags, setScheduleTags] = useState<any>([])
    const [isSpecialTag, setIsSpecialTag] = useState(false)
    const [scheduleData, setScheduleData] = useState<any>(null)
    const [adjustmentData, setAdjustmentData] = useState<any>([])
    const [countRecord, setCountRecord] = useState(0)
    let [checkBoxSelected, setCheckBoxSelected] = useState<any>({})
    let [isCandidatimeApiCalled, setIsCandidatimeApiCalled] = useState(false)
    const [isShowModal, setIsShowModal] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();
    const dataLocation = location.state?.dataLocation; 

    useEffect(() => {
        getListScheduleTags()
    }, [])

    useEffect(() => {
        if (sid) {
            getInfoSchedule(sid)
        } else {
            navigate('/adjustment/step1')
        }
    }, [sid, scheduleTags])

    useEffect(() => {
        if (participant.length === 0 || messageTimeError ) return
        setCheckAvailableData(false)
        setPage(1)
        setRows([])
        onRefreshOrLoadmoreData()
        setCheckBox([])
    }, [participant, filter])

    useEffect(() => {
        if (rows.length) {
            const rowsBeforeRender = groupByDate(rows, scheduleData);
            setRowsGroupByDate(rowsBeforeRender);
            // for each rowsGroupByDate, check if checkbox is true
            rowsBeforeRender.forEach((row: any, index: number) => {
                handleCheckBoxChange(true, index, row.start);
            });
        }
    }, [rows]);

    useEffect(() => {
        if (adjustmentData.length > 0) {
            const rowsBeforeRender = groupByDateForEditting(adjustmentData);
            setRowsGroupByDateForEditting(rowsBeforeRender)
        }
    }, [adjustmentData]);

    const isEditting = () => {
        return sid && currentUser?.is_employee && scheduleData?.status === "ADJUSTING" && dataLocation === undefined;
    }

    const onRefreshOrLoadmoreData = (loadPage = 1) => {
        setProgress(0)
        let params = {
            timeRanges: [
                {
                    start: filter.periodOfTime[0].toISOString(),
                    end: filter.periodOfTime[1].toISOString(),
                },
            ],
            attendees: participant.map((e: any) => {
                return {
                    type: "USER",
                    code: e.user.user_name,
                }
            }),
            timeInterval: filter.scheduleDuration,
            marginTime: filter.marginTime,
            timeFrame: filter.timeFrame,
            hide_holiday: filter.isHideHoliday,
            hide_busy: filter.isHideXUser
        }
        getAvailableTimes(sid, loadPage, params)
        // update search condition
        if(!isEditting()) {
            scheduleApi.updateSearchCondition({
                id: sid,
                search_condition: params
            })
        }
    }

    const getInfoSchedule = (sid: string) => {
        setIsLoading(true)
        const params = {
            id: sid,
        }
        scheduleApi
            .getById(params)
            .then((res: any) => {
                if (res?.success === false) {
                    // not found => go to step 1
                    navigate('/adjustment/step1')
                }
                const data = res.data
                const internalUsers = data.participant.filter((e: any) => e.user.is_employee)
                setParticipant(internalUsers)
                setScheduleData(data)
                if (!(sid && currentUser?.is_employee && data?.status === "ADJUSTING" && dataLocation === undefined)) {
                    if (data?.user?.candidateTimeFilterUser) {
                        // split time frame from start_time
                        let start_time = data?.user?.candidateTimeFilterUser.start_time.split(':');
                        let end_time = data?.user?.candidateTimeFilterUser.end_time.split(':');
                        setFilter({
                            timeFrame: data?.user?.candidateTimeFilterUser?.time_frame,
                            periodOfTime: data?.adjustment_start_date && data?.adjustment_end_date ? [
                                dayjs(data.adjustment_start_date).hour(Number(start_time[0])).minute(Number(start_time[1])),
                                dayjs(data.adjustment_end_date).hour(Number(end_time[0])).minute(Number(end_time[1])),
                            ] : [
                                dayjs(new Date()).hour(10).minute(0), 
                                dayjs(new Date()).hour(19).minute(0)
                            ],
                            scheduleDuration: data?.user?.candidateTimeFilterUser?.time_interval === 1439 ? 30 : data?.user?.candidateTimeFilterUser?.time_interval,
                            marginTime: data?.user?.candidateTimeFilterUser?.margin_time,
                            isHideXUser: data?.user?.candidateTimeFilterUser?.hide_busy,
                            isHideHoliday: data?.user?.candidateTimeFilterUser?.hide_holiday,
                        })
                    } else {
                        setFilter({
                            ...filter,
                            periodOfTime: data?.adjustment_start_date && data?.adjustment_end_date ? [
                                dayjs(data.adjustment_start_date).hour(10).minute(0),
                                dayjs(data.adjustment_end_date).hour(19).minute(0),
                            ] : [
                                dayjs(new Date()).hour(10).minute(0),
                                dayjs(new Date()).hour(19).minute(0)
                            ]
                        })
                    }
                }

                //Check schedule tag
                const verifyScheduleTag = scheduleTags.find((e: any) => e.name === data.event_menu);
                if (verifyScheduleTag &&
                    (verifyScheduleTag.color === CONSTANTS.SCHEDULE_TAG_COLOR.RED ||
                        verifyScheduleTag.color === CONSTANTS.SCHEDULE_TAG_COLOR.ORANGE)
                ) {
                    setIsSpecialTag(true);
                }

                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getAvailableTimes = (sid: any, loadPage: number, data: any) => {
        // prevent multiple api calls
        if (isCandidatimeApiCalled) return;
        setIsCandidatimeApiCalled(true);

        if (isEditting()) {
            adjustmentApi
            .getListAdjustmentByScheduleId(sid)
            .then((res: any) => {
                if (res?.success) {
                    setAdjustmentData(res.data.candidate_time)
                    setCheckBox((state) => {
                        const candidateTimeGroupByDate = groupByDateForEditting(res.data.candidate_time)
                        const stateCheckbox = Array(candidateTimeGroupByDate.length).fill(true)
                        return [...state, ...stateCheckbox]
                    })
                } else {
                    showSnackBar(true, `予定が無効です`, 'error')
                }
            })
            .catch((err) => {
                console.log('Finalzied -> adjustmentApi.getListAdjustmentByScheduleId failed: ', err)
            })
        }
        
        scheduleApi
        .getCandidateTimeV2(sid, {page: loadPage, take: 99999} , data)
        .then((res: any) => {
            let total = res.data.candidate_time.length > 0 ? res.data.candidate_time.length : 1
            let i = 0
            if (res.data && res.data.candidate_time.length > 0 && res.meta.pageIndex) {
                setPage(res.meta.pageIndex)
            }

            const BATCH_SIZE = 10;
            const timer = setInterval(() => {
                if (i > total) {
                    if (!isEditting()) {
                        if (loadPage == 1) {
                            setRows(res.data.candidate_time)
                        } else {
                            setRows([...rows, ...res.data.candidate_time])
                        }
                        if (res.data.candidate_time.length > 0) {
                            setCheckBox((state) => {
                                const candidateTimeGroupByDate = groupByDate(res.data.candidate_time, scheduleData)
                                const stateCheckbox = Array(candidateTimeGroupByDate.length).fill(true)
                                const setCheckBoxValue = [...state, ...stateCheckbox];
                                return setCheckBoxValue
                            })
                        }

                        if (res.meta.totalItems/99999 <= loadPage) {
                            setCheckAvailableData(true);
                        }

                        setCountRecord(res.meta.totalItems - res.meta.pageIndex * res.meta.pageSize);
                    }
                    setGaroonLink(res.data.garoon_view_link)
                    clearInterval(timer)
                    setProgress(-1)
                    setLoadedTime(dayjs().format('YYYY/M/D HH:mm'))
                    return
                }
                if (i % BATCH_SIZE === 0 || i === total) {
                    setProgress((i / total) * 100);
                }
                i++
            }, 10)

            return () => {
                if (timer) {
                    setProgress(-1)
                    clearInterval(timer)
                }
            };
        })
        .catch((err) => {
            if (err?.message.includes('Network Error')) {
                showSnackBar(true, `ネットワークエラー`, 'error')
            } 

            if (!isEditting()) {
                setProgress(-1)
            }
        })
        .finally(() => {
            // prevent multiple api calls
            setIsCandidatimeApiCalled(false);
        });
    }

    const [disableSubmit, setDisabledSubmit] = useState(false);
    const submit = (isModalSubmit = false) => {
        setIsLoading(true)
        if (isEditting()) {
            navigate(`/adjustment/step3/${sid}`)
            return;
        }

        const data = handleAdjustmentData();
        if (!Array.isArray(data) || data.length === 0) {
            showSnackBar(true, '最低ひとつの候補日時を選択してください。', 'error')
        } else {
            setDisabledSubmit(true)
            adjustmentApi
                .create(data)
                .then((res: any) => {
                    setDisabledSubmit(false)
                    if (isModalSubmit) {
                        navigate(`/adjustment/step3/${sid}`, { state: { dataLocation: {is_creating: true} } })
                    } else {
                        if (data.length > 1) {
                            showSnackBar(true, '社内のみ調整の場合は、候補日時を1つに絞ってください。', 'error')
                        } else {
                            navigate(`/adjustment/facility-selection/${sid}`, { state: { adjustmentData: { data: data } } })
                        }
                    }
                })
                .catch((err: any) => {
                    setDisabledSubmit(false)
                    console.log(err)
                })
        }
        setIsLoading(false)
    }

    const onChangeTimeFrame = debounce((data) => {
        setFilter({
            ...filter,
            timeFrame: data,
        })
    }, 500)

    const [messageTimeError, setMessageTimeError] = useState('');
    const onChangePeriodOfTime = debounce((prefix, data) => {
        let timeValidate = {
            start: filter.periodOfTime[0],
            end: filter.periodOfTime[1],
        };
        
        if (prefix === 'start') {
            timeValidate = {
                ...timeValidate,
                start: data
            }
        } else if (prefix === 'end'){
            timeValidate = {
                ...timeValidate,
                end: data
            }
        }

        // use yub validate time start and time end
        const schema = yup.object().shape({
            start: yup
                .date()
                .typeError('有効な時間を指定してください。')
                .required('必ず入力してください。'),
            end: yup
                .date()
                .typeError('有効な時間を指定してください。')
                .test('end-start', '終了時間は開始時間より後に設定してください。', function(value) {
                    // check Invalid Date
                    if (moment(value).isValid() == true && moment(this.parent.start).isValid()) {
                        let endTime = moment(value).format('HH:mm');
                        let startTime = moment(this.parent.start).format('HH:mm');
                        return endTime > startTime;
                    }

                    return true
                })
                .required('必ず入力してください。'),
        })
        
        schema.validate(timeValidate)
            .then(() => {
                setMessageTimeError('');
                // check difference HH:mm of timeValidate and periodOfTime
                if (timeValidate.start.format('HH:mm') >= timeValidate.end.format('HH:mm')) {
                    showSnackBar(true, `終了時間は開始時間より後の時間を指定してください。（エラーコード:400）`, 'error');
                    return;
                }

                if (prefix === 'start') {
                    if (timeValidate.start.format('HH:mm') === filter.periodOfTime[0].format('HH:mm')) {
                        return
                    }
                } else {
                    if (timeValidate.end.format('HH:mm') === filter.periodOfTime[1].format('HH:mm')) {
                        return
                    }
                }

                setFilter({
                    ...filter,
                    periodOfTime: [timeValidate.start, timeValidate.end],
                })
            })
            .catch((err) => {
                setMessageTimeError(err.errors[0]);
                setFilter({
                    ...filter,
                    periodOfTime: [timeValidate.start, timeValidate.end],
                })
            })
    }, 500)

    useEffect(() => {
        if (messageTimeError) {
            showSnackBar(true, `終了時間は開始時間より後の時間を指定してください。（エラーコード:400）`, 'error')
        }
    }, [messageTimeError]);

    const onChangeDuration = debounce((data) => {
        setFilter({
            ...filter,
            scheduleDuration: data,
        })
    }, 500)

    const getListScheduleTags = () => {
        adjustmentApi.getListScheduleTags().then((res: any) => {
            if (res.success) {
                setScheduleTags(res.data);
            }
            return res;
        })
        .catch((err) => {return []})
        .finally(() => {})
    }

    const handleCheckBoxChange = (isChecked: boolean, index: number, value: string) => {
        const data = getSessionCandidateTimeSelected();
        if (Object.keys(data).length > 0) {
            checkBoxSelected = data;
        }

        if (isChecked) {
            checkBoxSelected[index] = value;
        } else {
            delete checkBoxSelected[index];
        }
        const cleanedObjNull = Object.fromEntries(
            Object.entries(checkBoxSelected).filter(([key, value]) => value !== null)
        );

        setCheckBoxSelected(cleanedObjNull);
        sessionStorage.setItem(CANDIDATE_TIME_SESSION_KEY, JSON.stringify(cleanedObjNull));
    }

    let adjustmentSelectedData: any[] = [];
    const externalEventSubmit = () => {
        if (!checkCandidateSelected()) {
            return;
        }

        if (!isEditting()) {
            const data = handleAdjustmentData();
            if ((!Array.isArray(data) || data.length === 0)) {            
                showSnackBar(true, '最低ひとつの候補日時を選択してください。', 'error')
            } else {
                adjustmentApi
                .create(data)
                .catch((err: any) => {
                    console.log(err)
                })
            }
        }
        adjustmentSelectedData = handleAdjustmentData() || [];
        setIsShowModal(true);
    }

    const closeModal = () => setIsShowModal(false);

    const handleModalNextClick = () => {
        if (!checkCandidateSelected()) {
            return;
        }
        submit(true);
    }

    const checkCandidateSelected = () => {
        const data = Object.values(getSessionCandidateTimeSelected());
        if ((!data || data.length === 0) && !isEditting()) {
            showSnackBar(true, '最低ひとつの候補日時を選択してください。', 'error');
            return false;
        }

        return true;
    }

    const handleAdjustmentData = () => {
        if (isEditting()) {
            return;
        }
        
        if(rowsGroupByDate.length === 0) {
            return [];
        }

        const data = [];
        for (let i = 0; i < checkBox.length; i++) {
            const element = checkBox[i]
            if (element) {
                const rows = rowsGroupByDate[i].start
                for (let j = 0; j < rows.length; j++) {
                    const row = rows[j]

                    const candidateData = {
                        schedule_id: Number(sid),
                        start_time: row?.dateTime,
                        duration: filter.scheduleDuration,
                        margin_time: filter.marginTime,
                        participant: participant.map((e: any) => {
                            const userSchedule = row?.schedule_garoon.find(
                                (i: any) => i.code === e.user.user_name,
                            )
                            return {
                                participant_id: e.id,
                                status: userSchedule ? (userSchedule?.score ? userSchedule?.score: 0) : 5,
                            }
                        })
                    }

                    data.push(candidateData);
                }
            }
        }
        return data;
    };

    const handleBackToStep1 = () => {
        if (!isEditting()) {
            navigate(`/adjustment/step1/${sid}`, { state: { dataLocation: {is_creating: true} } });
        } else {
            navigate(`/adjustment/step1/${sid}`);
        }
    }

    return (
        <Container sx={{ p: '0 !important' }}>
            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={12} sx={{ mt: '12px' }}>
                        <ComponentStepper activeStep={1} />
                    </Grid>
                </Grid>
            </Box>
            <Paper elevation={3} sx={styles.paper}>
                <Box component="form" noValidate>
                    <Grid container spacing={2} sx={{alignItems: 'end'}}>
                        <Grid item xs={6} sm={4} md={3} lg={2}>
                            <Stack direction={'column'} spacing={1}>
                                <Typography sx={styles.label}>予定時間</Typography>
                                <SelectCustom
                                    value={filter.scheduleDuration}
                                    onChange={(e: any) => onChangeDuration(e.target.value)}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontWeight: 'bold !important',
                                        },
                                    }}
                                    disabled={isEditting()}
                                >
                                    {CONSTANTS.SCHEDULED_TIME.map((item) => item.value !== 1439 && (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </SelectCustom>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3} lg={2}>
                            <Stack direction={'column'} spacing={1}>
                                <Typography sx={styles.label}>{isSpecialTag ? '移動時間' : '前後時間'}</Typography>
                                <SelectCustom 
                                    value={filter.marginTime} 
                                    onChange={(e: any) => setFilter({...filter, marginTime: e.target.value})}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontWeight: 'bold !important',
                                        },
                                    }}
                                    disabled={isEditting()}
                                >
                                    {CONSTANTS.MARGIN_TIME.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </SelectCustom>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} xsm={4.7} sm={4} md={3} lg={2}>
                            <Stack direction={'column'} spacing={1}>
                                <Typography sx={styles.label}>開始時間</Typography>
                                <SelectCustom
                                    value={filter.timeFrame}
                                    onChange={(e: any) => onChangeTimeFrame(e.target.value)}
                                    disabled={isEditting()}
                                >
                                    {CONSTANTS.TIME_FRAME.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </SelectCustom>
                            </Stack>
                        </Grid>
                        <Grid 
                            item 
                            xs={12}
                            xsm={7.3}
                            sm={6}
                            md={3}
                            lg={2}
                            sx={{minWidth: {xs: '205px', sm: '230px'}}}
                        >
                            <Stack direction={'column'} spacing={1}>
                                <Typography sx={styles.label}>時間帯</Typography>
                                <Stack 
                                    direction={'row'} 
                                    sx={[{
                                        display: 'flex', 
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '15px',
                                        border: messageTimeError ? '1px solid red' : '1px solid #666',
                                        height: '48px',
                                        padding: '0px 15px',
                                    }, isEditting() && {
                                        backgroundColor: '#eaeaea',
                                        cursor: "not-allowed"
                                    }
                                    ]}
                                >
                                    <LocalizationProvider 
                                        dateAdapter={AdapterDayjs}
                                        localeText={{
                                            okButtonLabel: '決定',
                                            cancelButtonLabel: 'キャンセル',
                                            timePickerToolbarTitle: '時間を選択',
                                        }}
                                    >
                                        <DesktopTimePicker
                                            disabled={isEditting()}
                                            ampm={false}
                                            format='HH:mm'
                                            timezone="system"
                                            maxTime={dayjs(new Date()).hour(23).minute(59)}
                                            minTime={dayjs(new Date()).hour(0).minute(0)}
                                            open={openStartTime}
                                            onOpen={() => setOpenStartTime(true)}
                                            onClose={() => setOpenStartTime(false)}
                                            value={filter.periodOfTime[0]}
                                            onChange={(value: any) => {
                                                onChangePeriodOfTime('start', value)
                                            }}
                                            slotProps={{
                                                textField: {
                                                    sx: [{
                                                        fieldset: {
                                                            borderColor: 'transparent !important',
                                                        },
                                                        ' & .MuiInputBase-root': {
                                                            padding: '0px',
                                                        },
                                                        ' & .MuiInputBase-root input': {
                                                            paddingBottom: '10px',
                                                        },
                                                        
                                                    }, isEditting() && {
                                                        ' & .MuiInputBase-root input': {
                                                            cursor: 'not-allowed',
                                                            WebkitTextFillColor: '#070707'
                                                        },
                                                        ' & .MuiButtonBase-root': {
                                                            cursor: 'not-allowed'
                                                        }
                                                    }
                                                    ],
                                                    size: 'small',
                                                    onClick: () => {
                                                        if (isEditting()) {
                                                            setOpenStartTime(false)
                                                        } else {
                                                            setOpenStartTime(true)
                                                        }
                                                    }
                                                },
                                                inputAdornment: {
                                                    position: 'start',
                                                    sx: {
                                                        margin: '0px',
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: '21px',
                                                        }
                                                    },
                                                },
                                                popper: {
                                                    sx: {
                                                        "& .MuiMenuItem-root": {
                                                            "&.Mui-selected": {
                                                                color: '#fff',
                                                            },
                                                        },
                                                    }
                                                }
                                            }}
                                        />
                                        <DesktopTimePicker
                                            disabled={isEditting()}
                                            ampm={false}
                                            format='HH:mm'
                                            timezone="system"
                                            open={openEndTime}
                                            maxTime={dayjs(new Date()).hour(23).minute(59)}
                                            minTime={dayjs(new Date()).hour(0).minute(0)}
                                            onOpen={() => setOpenEndTime(true)}
                                            onClose={() => setOpenEndTime(false)}
                                            value={filter.periodOfTime[1]}
                                            onChange={(value: any) => {
                                                onChangePeriodOfTime('end', value)
                                            }}
                                            slotProps={{
                                                textField: {
                                                    sx: [{
                                                        fieldset: {
                                                            borderColor: 'transparent !important',
                                                        },
                                                        ' & .MuiInputBase-root': {
                                                            padding: '0px',
                                                        },
                                                        ' & .MuiInputBase-root input': {
                                                            paddingBottom: '10px',
                                                        },
                                                    },isEditting() && {
                                                        ' & .MuiInputBase-root input': {
                                                            cursor: 'not-allowed',
                                                            WebkitTextFillColor: '#070707'
                                                        },
                                                        ' & .MuiButtonBase-root': {
                                                            cursor: 'not-allowed'
                                                        }
                                                    }],
                                                    size: 'small',
                                                    onClick: () => {
                                                        if (isEditting()) {
                                                            setOpenEndTime(false)
                                                        } else {
                                                            setOpenEndTime(true)
                                                        }
                                                    }
                                                },
                                                inputAdornment: {
                                                    position: 'start',
                                                    sx: {
                                                        margin: '0px',
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: '21px',
                                                            WebkitTextFillColor: '#070707'
                                                        }
                                                    }
                                                },
                                                popper: {
                                                    sx: {
                                                        "& .MuiMenuItem-root": {
                                                            "&.Mui-selected": {
                                                                color: '#fff',
                                                            },
                                                        },
                                                    }
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                                {/* {
                                    messageTimeError && <FormHelperText sx={{ color: 'red' }}>{messageTimeError}</FormHelperText>
                                } */}
                            </Stack>
                        </Grid>
                        <Grid item sx={{height: '50px'}}>
                            <Stack direction={'column'}>
                                <FormControlLabel
                                    control={
                                        <CheckBoxCustom
                                            checked={filter.isHideHoliday}
                                            onChange={() => setFilter({...filter, isHideHoliday: !filter.isHideHoliday})}
                                            prefix={true}
                                            disabled={isEditting()}
                                        />
                                    }
                                    sx={[styles.formLabel, isEditting() && {cursor: "not-allowed !important", WebkitTextFillColor: '#070707'}]}
                                    label="休日・祝日を除く"
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} columnGap={2} rowGap={1} sx={{mt: 2}}>
                        <Grid item xs={12}>
                            <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed', marginBottom: loadedTime ? '5px' : '24px' }}>
                                <Box sx={{ width: '100%', textAlign: 'right', verticalAlign: 'center' }}>
                                {garoonLink &&
                                    <Link underline="none" color={'#0075FF'} sx={{ fontSize: '15px', lineHeight: '20px', fontWeight: '400', cursor: "pointer" }} href={garoonLink || '#'} target="_blank">
                                        <Box component={'span'} sx={{borderBottom: '1px solid #0075FF'}}>Garoonで見る</Box>
                                        <Box component={'span'} sx={{verticalAlign: 'bottom', paddingLeft: '2px', position: 'relative', top: '1px'}}><ExternalLinkIcon/></Box>
                                    </Link>
                                }
                                </Box>
                                <TableContainer sx={styles.table}>
                                    { progress == -1 && <Typography sx={{fontSize: '16px', fontWeight: '400', lineHeight: '24px'}}>以下、<Typography component={'span'} sx={{fontWeight: '500', fontSize: '24px'}}>{adjustmentData.length || rowsGroupByDate.reduce((total: number, group: any) => total + group.start.length, 0)}</Typography>件の空き枠が一致しました。</Typography> }
                                    <TableWithStickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {isEditting() ?
                                                progress == -1 &&
                                                <>
                                                    {Array.isArray(rowsGroupByDateForEditting)  && rowsGroupByDateForEditting.map((row: any, index: number) => {
                                                        let rowPoint = 0
                                                        return (
                                                            <TableRow
                                                                key={row.id}
                                                                tabIndex={0}
                                                                hover
                                                            >
                                                                <TableCell
                                                                sx={{
                                                                    width: '5px',
                                                                    padding: '8px 0px !important',
                                                                }}
                                                                >・</TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    sx={{
                                                                        fontWeight: '400',
                                                                        fontSize: '16px',
                                                                        verticalAlign: 'middle',
                                                                        padding: {
                                                                            sm: '0px !important',
                                                                            xs: '0px !important',
                                                                        },
                                                                        lineHeight: {
                                                                            xs: '1.2em',
                                                                            sm: '30px',
                                                                        },
                                                                        minWidth: {
                                                                            xs: '130px',
                                                                        },
                                                                    }}
                                                                >
                                                                    { row.duration !== 1439 
                                                                        ? (<>
                                                                            {formatDateTimeWithDayOfWeek(row.start)}
                                                                        </>)
                                                                        : (<>
                                                                            {dayjs(row.start_time).format(
                                                                                `YYYY/M/D(${
                                                                                    CONSTANTS.DAY_OF_WEEK[dayjs(row.start_time).day()]
                                                                                })`,
                                                                            )}
                                                                        </>)
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </>
                                                :
                                                <>
                                                {rows.length > 0 && rowsGroupByDate.length > 0 &&
                                                rowsGroupByDate.map((row: any, index: number) => {
                                                    return row.start.length > 0 && (
                                                        <TableRow
                                                            key={row.start.dateTime}
                                                            tabIndex={0}
                                                            hover
                                                            // sx={[
                                                            //     (checkBox[index]) && {
                                                            //         backgroundColor: '#fffddb',
                                                            //         ':hover': {
                                                            //             backgroundColor: '#fff6b0 !important'
                                                            //         }
                                                            //     }
                                                            // ]}
                                                            // onClick={() => {
                                                            //     console.log(row)
                                                            //     setCheckBox((prev) => {
                                                            //         let currentCheckBox = [...prev]
                                                            //         currentCheckBox[index] = !currentCheckBox[index]
                                                            //         handleCheckBoxChange(currentCheckBox[index], index, row.start);
                                                            //         return currentCheckBox
                                                            //     })
                                                            // }}
                                                        >
                                                            <TableCell
                                                                sx={{
                                                                    width: '5px',
                                                                    padding: '8px 0px !important',
                                                                }}
                                                            >・</TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    verticalAlign: 'middle',
                                                                    padding: {
                                                                        sm: '0px 0px !important',
                                                                        xs: '0px 0px !important',
                                                                    },
                                                                    lineHeight: {
                                                                        xs: '1.2em',
                                                                        sm: '30px',
                                                                    },
                                                                    minWidth: {
                                                                        xs: '130px',
                                                                        sm: '260px',
                                                                    },
                                                                }}
                                                            >
                                                                { filter.scheduleDuration !== 1439 
                                                                    ? (<>
                                                                        {formatDateTimeWithDayOfWeek(row.start)} 
                                                                    </>)
                                                                    : (<>
                                                                        {dayjs(row.start[0]?.dateTime).format(
                                                                            `YYYY/M/D(${
                                                                                CONSTANTS.DAY_OF_WEEK[dayjs(row.start[0]?.dateTime).day()]
                                                                            })`,
                                                                        )}
                                                                    </>)
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                                </>
                                            }
                                        </TableBody>
                                    </TableWithStickyHeader>
                                </TableContainer>
                            </Box>
                        </Grid>
                    </Grid>
                    {(progress < 0 && loadedTime) &&
                        <Grid 
                            sx={{
                                textAlign: {
                                    xs: 'left',
                                    sm: 'right'
                                },
                                marginBottom: {
                                    xs: '5px',
                                    md: '0px'
                                },
                                fontSize: '14px'
                            }}
                        >
                            ※{loadedTime}時点の予定です
                        </Grid>
                    }
                </Box>
                {
                    // show loading when click button more
                    (() => {
                        if (progress >= 0) {
                            return (
                                <Box sx={{ width: '200px', margin: 0, }}>
                                    <LinearProgressWithLabel value={progress} />
                                </Box>
                            )
                        } else if (rows.length > 0) {
                            if (checkAvailableData)  {
                                return (
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography sx={{ fontSize: '15px', fontWeight: '500', color: '#999' }}>全件表示済み</Typography>
                                    </Box>
                                )
                            } else {
                                return (
                                    <>
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <ButtonCustom child={'もっと見る'} onClick={() => {
                                                onRefreshOrLoadmoreData(page + 1)
                                            }}/>
                                        </Box>
                                        <Typography sx={{ fontSize: '14px' }}>残り{countRecord}件</Typography>
                                    </>
                                )
                            }
                        } else if (isEditting()) {
                            return (
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography sx={{ fontSize: '15px', fontWeight: '500', color: '#999' }}>全件表示済み</Typography>
                                </Box>
                            )
                        } else if (rows.length === 0 && checkAvailableData) {
                            return (
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography sx={{ fontSize: '15px', fontWeight: '500', color: '#999' }}>結果が表示されません</Typography>
                                </Box>
                            )
                        }
                        return null
                    })()
                }
                <Box sx={styles.footer}>
                    <ButtonCustom child={'戻る'} onClick={handleBackToStep1} sx={{ marginRight: width < 500 ? '10px' : undefined }}/>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <ButtonCustom
                        child={'調整依頼'} //Open dialog
                        onClick={() => externalEventSubmit()}
                        isSubmit
                        endIcon={<ArrowForwardIosIcon sx={{ fontSize: '14px !important' }} />}
                        disabled={disableSubmit}
                    />
                </Box>
            </Paper>
            <SingleExternalUserModal
                isOpen={isShowModal}
                isLoading={isLoading}
                onClose={closeModal}
                scheduleData={scheduleData}
                onNextBtnClick={handleModalNextClick}
                adjustmentData={adjustmentSelectedData}
                isEditting={isEditting()}
                filter={filter}
            />
        </Container>
    )
}

const CheckBoxCustom = ({ checked, onChange, prefix = false, disabled = false, checkBoxValue = null }: any) => {
    return (
        <Checkbox
            disabled={disabled}
            sx={[{
                color: '#000000',
                padding: prefix ? '0 0 0 9px' : '8px',
                zIndex: 0,
            }, disabled && {
                cursor: "not-allowed !important"
            }]}
            checked={checked}
            onChange={onChange}
        ></Checkbox>
    )
}

const styles = {
    paper: {
        p: { xs: '20px', sm: '30px', md: 5 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0px 4px 120px rgba(0, 0, 0, 0.25)',
        marginTop: '32px',
        borderRadius: '20px',
        border: '1px solid transparent',
    },
    label: {
        ...labelOfInput,
        ...labelOfInputAlign,
    },
    table: {
        th: {
            ...tableHeaderText,
            ...tableRowAlign,
            border: 'none',
        },
        'tbody > tr': {
            verticalAlign: 'top',
        },
        tr: {
            cursor: 'pointer',
            border: 'none !important',
        },
        td: {
            ...tableBodyText,
            ...tableRowAlign,
            border: 'none !important',
        },
    },
    inputTimeRange: {
        '.MuiInputBase-root': {
            ...inputField,
            height: '50px',
        },
        fieldset: {
            borderRadius: '15px',
            border: '1px solid #666 !important',
            height: '55px',
        },
    },
    footer: { display: 'flex', flexDirection: 'row', width: '100%', mt: 5 },
    formLabel: {
        '.MuiTypography-root': {
            ...labelOfInput,
        },
        marginRight: '0px',
    },
}

export default AdjustmentSingleExternalStep2