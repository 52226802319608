import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles';
import {
    Checkbox,
    FormHelperText,
    Stack,
    Typography,
    Chip,
    Container,
    FormControlLabel,
    Paper,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import scheduleApi from 'src/api/scheduleApi'
import adjustmentApi from 'src/api/adjustmentApi'
import dayjs from 'dayjs'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextInput } from 'src/components/TextInput'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded'
import PeopleAltSharpIcon from '@mui/icons-material/PeopleAltSharp'
import SettingsIcon from '@mui/icons-material/Settings'
import { CONSTANTS } from 'src/config/constants'
import { tableRowAlign, tableBodyText, tableHeaderText, labelOfInput, labelOfInputAlign } from 'src/theme'
import ButtonCustom from 'src/components/Common/ButtonCustom'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { topActions } from 'src/pages/Top/topSlice'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { replaceGaroonName } from '../../helpers/helpers'
import useWindowDimensions from 'src/hook/useWindowDimensions'
import { HelpCenterIcon } from 'src/components/Icons'
import ComponentStepper from '../adjustment/components/Stepper';
import { selectInfoSchedule } from '../adjustment/adjustmentSlice';
import { forEach } from 'lodash';

const schema = yup.object({
    email_subject: yup
        .string()
        .required('必ず入力してください。')
        .max(100, 'タイトルは100文字以内で入力してください。'),
    email_body: yup.string().required('必ず入力してください。').max(5000, '本文は5000文字以内で入力してください。'),
})

const FinalizeStep3 = () => {
    const dispatch = useAppDispatch()
    const { sid } = useParams()
    const { showSnackBar } = useSnackBar()
    const navigate = useNavigate()
    const [scheduleData, setScheduleData] = useState<any>(null)
    const [notRepondentUsers, setNotRepondentUsers] = useState<any>(null)
    const [checkEventMenu, setCheckEventMenu] = useState(true)
    const { height, width } = useWindowDimensions();
    const [isLoading, setIsLoading] = useState(false)

    const infoSchedule = useAppSelector(selectInfoSchedule)

    const [selectedAdjustment, setSelectedAdjustment] = useState<any>(null)
    const [selectedFacilites, setSelectedFacilites] = useState<any>(null)
    const [selectedNote, setSelectedNote] = useState<string>('')

    const location = useLocation();
    const [locationState] = useState(location?.state || {});

    useEffect(() => {
        if (locationState?.adjustmentData && locationState?.facilities) {
            setSelectedAdjustment(locationState?.adjustmentData)
            setSelectedFacilites(locationState?.facilities)
            setSelectedNote(locationState?.note ? locationState?.note : '')
        } else {
            navigate('/schedule-list')
        }
    }, [locationState])

    const {
        control,
        handleSubmit,
        getValues,
        reset,
        formState: { isSubmitting, errors, isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            email_subject: '',
            email_body: '',
            is_cc_internal_participants: true,
            is_send_to_non_reponded_user: true,
            attachment_file: [],
        },
        values: {
            email_subject: `【GLOBAL PRODUCE】${scheduleData?.subject || ''}／日程確定のお知らせ`,
            email_body: `{name} 様<br><br>いつもお世話になっております。<br>` 
                + `グローバルプロデュースの${replaceGaroonName(scheduleData?.user, width, true)}です。<br><br>`
                + `以下の日時でスケジュールが確定いたしましたので<br>ご確認のほどよろしくお願い致します。<br><br>`
                + `---<br>タイトル：${
                    scheduleData?.subject || ''
                }<br>日時：${selectedAdjustment?.duration !== 1439 
                    ? dayjs(selectedAdjustment?.start_time).format(`YYYY/M/D(${
                        CONSTANTS.DAY_OF_WEEK[dayjs(selectedAdjustment?.start_time).day()]
                    }) H:mm`,) || ''
                    : dayjs(selectedAdjustment?.start_time).format(`YYYY/M/D(${
                        CONSTANTS.DAY_OF_WEEK[dayjs(selectedAdjustment?.start_time).day()]
                    })`)
                }〜（${
                    selectedAdjustment?.duration
                        ? selectedAdjustment?.duration === 1439
                            ? '終日'
                            : selectedAdjustment?.duration + '分間'
                        : ''
                }）<br><br>`
                + `${selectedNote ? ('<a href="' + selectedNote + '">' + selectedNote + '</a><br><br>') : ''}`
                + `当日お会いできるのを<br>楽しみにしております。<br><br>`
                + `----------------------------------------------------<br>`
                + `株式会社グローバルプロデュース<br>`
                + `${replaceGaroonName(scheduleData?.user, width, true)}<br>`
                + `〒150-0001    東京都渋谷区神宮前5-52-2<br>`
                + `青山オーバルビル13F</br>`
                + `OFFICE／03-5738-2117 （FAX2118）<br>`
                + `https://www.global-produce.jp/`
        },
        resolver: yupResolver(schema),
    })

    const [scheduleTags, setScheduleTags] = useState<any>([])
    const getListScheduleTags = () => {
        adjustmentApi.getListScheduleTags().then((res: any) => {
            if (res.success) {
                setScheduleTags(res.data);
            }
            return;
        })
        .catch((err) => {
            return;
        })
    }

    useEffect(() => {
        const params = {
            id: Number(sid),
        }
        scheduleApi
            .getById(params)
            .then((res) => {
                let data = res.data

                if (data == undefined || data == null || data.length == 0 ) {
                    showSnackBar(true, `予定が無効です`, 'error')
                    navigate('/schedule-list')
                }

                if (!res.data.event_menu || res.data.event_menu.trim().length === 0) {
                    setCheckEventMenu(false)
                }

                if (data.status !== 'ADJUSTING' || data.adjustment_end_date < dayjs().add(3, 'hour').toISOString()) {
                    showSnackBar(true, `予定が無効です`, 'error')
                    navigate('/schedule-list')
                }
                setScheduleData(data)
                let notRepodent = data.participant.filter((e: any) => !e.is_confirmed && e.hash != null);
                setNotRepondentUsers(notRepodent);
            })
            .catch((err) => {
                console.log('Finalzied -> scheduleApi.getById failed: ', err)
                showSnackBar(true, `予定が無効です`, 'error')
                navigate('/schedule-list')
            })

        getListScheduleTags()
    }, [])

    const handleFormSubmit = (data: any) => {
        setIsLoading(true)
        let facilityArr = [];
        if (infoSchedule?.facilities?.length > 0) {
            facilityArr = infoSchedule.facilities.map((obj: any) => {
                return {
                    code: obj.code,
                    name: obj.name,
                }
            })
        }
        const adjustment = selectedAdjustment
        const params = {
            start_time: adjustment.start_time,
            end_time: dayjs(adjustment.start_time).add(adjustment.duration, 'minute').toISOString(),
            margin_time: adjustment.margin_time,
            email_subject: data.email_subject,
            email_body: data.email_body,
            is_cc_internal_participants: data.is_cc_internal_participants,
            is_send_to_non_reponded_user: data?.is_send_to_non_reponded_user ? data?.is_send_to_non_reponded_user : false,
            attachment_file: filesData,
            note: infoSchedule.note,
            facilities: facilityArr,
        }
        finalize(sid, params)
        setIsLoading(false)
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const fileMimeType = [
        'image/jpeg',
        'image/png',
        'image/jpg',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ]

    const [fileAttachment, setFileAttachment] = useState<any[]>([]);
    const [filesData, setFilesData] = useState<any[]>([]);
    const useRefFileInput = useRef<any>(null);
    const [errorFile, setErrorFile] = useState('');
    const handleFileAttachment = (event: any) => {
        const {files} = event.target;

        const validImageFiles = [];
        let isValidate = false;
        setErrorFile('');
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!fileMimeType.includes(file.type)) {
                // reset file input
                if(useRefFileInput && useRefFileInput.current) {
                    useRefFileInput.current.value = '';
                }
                setErrorFile('ファイル形式が正しくありません');
                isValidate = true;
                return;
            }
            if (file.size > 10485760) {
                // reset file input
                if(useRefFileInput && useRefFileInput.current) {
                    useRefFileInput.current.value = '';
                }
                setErrorFile('10MB以下のファイルを添付してください。');
                isValidate = true;
                return;
            }

            validImageFiles.push(file);
        }

        if (!isValidate && validImageFiles.length > 0) {
            setFileAttachment(validImageFiles);
        }
    }

    useEffect(() => {
        // reset file input
        if(useRefFileInput && useRefFileInput.current) {
            useRefFileInput.current.value = '';
        }

        let fileReaders: any[] = [];
        let isCancel = false;
        if (fileAttachment.length > 0) {
            forEach(fileAttachment, (file: any) => {
                let fileReader = new FileReader();
                fileReaders.push(fileReader);
                fileReader.onload = (e: any) => {
                    const { result } = e.target;
                    if (result && !isCancel) {
                        setFilesData((prev: any) => {
                            return [
                                ...prev,
                                {
                                    name: file.name,
                                    size: file.size,
                                    type: file.type,
                                    data: result,
                                },
                            ];
                        });    
                    }
                };
                fileReader.readAsDataURL(file);
            })
        }

        return () => {
            isCancel = true;
            fileReaders.forEach(fileReader => {
                if (fileReader.readyState === 1) {
                    fileReader.abort()
                }
            })
        }
    }, [fileAttachment]);

    const handleDeleteFile = (file: any, index: number) => () => {
        setFilesData((prev: any) => {
            const newFilesData = [...prev];
            newFilesData.splice(index, 1);
            return newFilesData;
        });
    };

    const componentAttachmentFile = () => {
        return (
            <>
                <Stack 
                    direction={
                        {
                            xs: 'column',
                            sm: 'row',
                        }
                    } 
                    spacing={1}
                >
                    <Controller
                        name="attachment_file"
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Typography
                                component="label"
                                sx={{
                                    color: '#1976d2',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    fontSize: '15px',
                                    minWidth: '140px',
                                    padding: '4px 0 4px 0px',
                                }}
                            >
                                <VisuallyHiddenInput
                                    hidden
                                    multiple
                                    ref={useRefFileInput}
                                    onChange={(event) => handleFileAttachment(event)}
                                    name='attachment_file'
                                    accept="
                                        image/jpeg,image/jpg,image/png
                                        ,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document
                                        ,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
                                        ,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation
                                    "
                                    type="file"
                                    value={''}
                                />
                                ファイルを添付する
                            </Typography>
                        )}
                    />
                    {
                        (() => {
                            let attachmentFiles = filesData;
                            if (attachmentFiles && attachmentFiles.length > 0) {
                                return (
                                    <Stack direction={'row'} spacing={1} useFlexGap sx={{flexWrap: 'wrap'}}>
                                        {
                                            attachmentFiles.map((file: any, index: number) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        label={file.name}
                                                        onDelete={handleDeleteFile(file, index)}
                                                        sx={{ fontSize: '15px' }}
                                                    />
                                                )
                                            })
                                        }
                                    </Stack>
                                )
                            }
                        })()
                    }
                </Stack>
                <FormHelperText
                    error={errorFile.length > 0}
                >
                    {errorFile}
                </FormHelperText>
            </>
        )
    }

    const finalize = (sid: any, params: any) => {
        scheduleApi
            .finalize(sid, params)
            .then((res: any) => {
                if (res?.success) {
                    dispatch(topActions.setScheduleFinalized(scheduleData))
                    showSnackBar(true, `予定「${scheduleData.subject}」の日時が確定しました`, 'success')
                    navigate('/schedule-list', {state: { selectTab:'FINALIZED'} })
                } else {
                    console.log(res.message)
                }
            })
            .catch((err) => console.log(err))
    }

    if (scheduleData === null) {
        return
    }

    return (
        <Container sx={{ p: '0 !important'}}>
            <Box>
                <Grid container spacing={4}>
                    <Grid xs={12} sx={{ mt: '12px' }}>
                        <ComponentStepper activeStep={2} listSteps={['1.日時確定', '2.施設選択', '3.メール送信']} />
                    </Grid>
                </Grid>
            </Box>
            <Paper elevation={3} sx={styles.paper}>
            <Box component="form" noValidate>
                <Grid container spacing={0} sx={styles.container}>
                    <Box sx={styles.adjustmentTitle}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            gap={1}
                        >
                            <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}} 
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >
                                    <InsertDriveFileOutlinedIcon sx={styles.boxAddInviteeIcon}/>
                                    <Typography sx={styles.label}>予定タイトル</Typography>
                                </Stack>
                                <Stack
                                    direction={'row'}
                                    spacing={{ xs: 1, xsm: 2}}
                                    sx={{
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 130px)',
                                        },
                                    }}
                                >
                                    {
                                        (() => {
                                            if (checkEventMenu) {
                                                let tag = scheduleTags.find((element: any) => element?.name == scheduleData?.event_menu)
                                                return (
                                                    <Box 
                                                        sx={[
                                                            styles.boxScheduleEventMenu, 
                                                            {
                                                                height: '21px',
                                                                backgroundColor: tag && tag?.color ? tag?.color : '#9F7AEA',
                                                            }
                                                        ]}
                                                    >
                                                        <Typography sx={styles.boxScheduleEventMenuText}>
                                                            {scheduleData.event_menu}
                                                        </Typography>
                                                    </Box>
                                                )
                                            } else {
                                                return ''
                                            }
                                        })()
                                    }
                                    <Typography
                                        sx={{ 
                                            lineHeight: 1.4, 
                                            fontSize: '15px',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        {scheduleData.subject}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}} 
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >
                                    <EventNoteRoundedIcon sx={styles.boxAddInviteeIcon}/>
                                    <Typography sx={styles.label}>予定日時</Typography>
                                </Stack>
                                <Stack 
                                    direction={'row'}
                                    sx={{
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 130px)',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            lineHeight: 1.4,
                                            fontSize: '15px',
                                            whiteSpace: 'normal'
                                        }}
                                    >
                                        { selectedAdjustment 
                                            && (selectedAdjustment.duration !== 1439 
                                                ? (<>
                                                    {dayjs(selectedAdjustment.start_time).format(
                                                        `YYYY/M/D(${
                                                            CONSTANTS.DAY_OF_WEEK[dayjs(selectedAdjustment.start_time).day()]
                                                        }) H:mm`,
                                                    )}
                                                    {`〜 `}
                                                    {selectedAdjustment.duration !== 1439 ? `(${selectedAdjustment.duration}分)` : '23:59'}
                                                </>)
                                                : (<>
                                                    {dayjs(selectedAdjustment.start_time).format(
                                                        `YYYY/M/D(${
                                                            CONSTANTS.DAY_OF_WEEK[dayjs(selectedAdjustment.start_time).day()]
                                                        })`,
                                                    )}
                                                </>)
                                            )
                                        }
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}} 
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >
                                    <PeopleAltSharpIcon sx={styles.boxAddInviteeIcon}/>
                                    <Typography sx={styles.label}>参加者</Typography>
                                </Stack>
                                <Stack 
                                    direction={'row'}
                                    sx={{
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 130px)',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            lineHeight: 1.4, 
                                            fontSize: '15px',
                                            whiteSpace: 'normal'
                                        }}
                                    >
                                        {scheduleData.participant.map((e: any) => replaceGaroonName(e.user, width)).join('、')}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}} 
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >   
                                    <HelpCenterIcon styles={{fontSize: '21px', width: '1em', height: '1em'}}/>
                                    <Typography sx={styles.label}>未回答者</Typography>
                                </Stack>
                                <Stack 
                                    direction={'row'}
                                    sx={{
                                        marginLeft: 0,
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 134px)',
                                        },
                                        display: {
                                            xs: 'flex'
                                        },
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'row'
                                        }
                                    }}
                                >
                                    <Stack 
                                        direction={'row'}
                                        sx={{
                                           
                                        }}
                                    >
                                    {notRepondentUsers !== null && notRepondentUsers !== undefined && notRepondentUsers.length > 0?
                                        <Typography
                                            sx={{
                                                lineHeight: 1.4, 
                                                fontSize: '15px',
                                                whiteSpace: 'normal'
                                            }}
                                        >
                                            {notRepondentUsers.map((e: any) => {
                                                return replaceGaroonName(e.user, width)
                                            }).join('、')}
                                        </Typography>
                                        :
                                        <>-</>
                                    }
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}} 
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >
                                    <SettingsIcon sx={styles.boxAddInviteeIcon}/>
                                    <Typography sx={styles.label}>施設</Typography>
                                </Stack>
                                <Stack 
                                    direction={'row'}
                                    sx={{
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 130px)',
                                        },
                                    }}
                                >
                                    {selectedFacilites.length > 0 ?
                                        <Typography
                                            sx={{
                                                lineHeight: 1.4, 
                                                fontSize: '15px',
                                                whiteSpace: 'normal'
                                            }}
                                        >
                                            {selectedFacilites.map((e: any) => e.name).join('、')}
                                        </Typography>
                                        :
                                        <>-</>
                                    }
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>

                    <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed', marginBottom: '24px' }}>
                    <Grid container xs={12} sx={{ marginTop: '24px'}}>
                        <Grid xs={12}>
                            <Stack spacing={1} sx={{ float: { xs: 'none', sm: 'left'}, marginRight: '40px' }}>
                                <Controller
                                    name="is_cc_internal_participants"
                                    control={control}
                                    defaultValue={true}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        color: '#000000',
                                                        padding: '0px 7px 0px 0px',
                                                    }}
                                                    checked={value}
                                                    onChange={(e) => onChange(e.target.checked)}
                                                />
                                            }
                                            sx={{
                                                '.MuiTypography-root': {
                                                    fontSize: '15px',
                                                    fontWeight: '500',
                                                },
                                            }}
                                            label="参加者にもCCで送付する"
                                        />
                                    )}
                                />
                            </Stack>
                            {
                                notRepondentUsers !== null && notRepondentUsers !== undefined && notRepondentUsers.length > 0 &&
                                <Stack spacing={1} sx={{ marginTop: { xs: '5px', sm: '0px'} }}>
                                    <Controller
                                        name="is_send_to_non_reponded_user"
                                        control={control}
                                        defaultValue={true}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            color: '#000000',
                                                            padding: '0px 7px 0px 0px',
                                                        }}
                                                        checked={value}
                                                        onChange={(e) => onChange(e.target.checked)}
                                                    />
                                                }
                                                sx={{
                                                    '.MuiTypography-root': {
                                                        fontSize: '15px',
                                                        fontWeight: '500',
                                                    },
                                                }}
                                                label="未回答者にも送付する"
                                            />
                                        )}
                                    />
                                </Stack>
                            }
                           
                        </Grid>
                        <Grid xs={12}>
                            <Stack spacing={1} sx={{ mt: 3 }}>
                                <Typography sx={{ ...labelOfInput, ...labelOfInputAlign }}>件名</Typography>
                                <Controller
                                    name="email_subject"
                                    control={control}
                                    defaultValue={getValues('email_subject')}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                            <TextInput
                                                onChange={(event: any) => {
                                                    onChange(event.target.value.toString())
                                                }}
                                                value={value}
                                            />
                                            {error ? (
                                                <FormHelperText error> {error.message} </FormHelperText>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12}>
                            <Stack spacing={1} sx={{ mt: 3, mb: '5px'}}>
                                <Typography sx={{ ...labelOfInput, ...labelOfInputAlign }}>本文</Typography>
                                <Controller
                                    name="email_body"
                                    control={control}
                                    defaultValue={getValues('email_body')}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                            <Typography 
                                                component={'div'} 
                                                sx={{
                                                    marginTop: '8px',
                                                    border: '1px solid #666',
                                                    borderRadius: '15px',
                                                    fontSize: '15px',
                                                }}
                                            >
                                                <CKEditor
                                                    editor={DecoupledEditor}
                                                    data={value}
                                                    config={{
                                                        removePlugins: ['Title'],
                                                        toolbar: [ 'undo', 'redo', '|', 'bold', 'fontColor' ],
                                                        placeholder: '',
                                                        translations: {
                                                            '': {
                                                                dictionary: {
                                                                    'Remove color': '色を削除',
                                                                    'Color picker': 'その他の色',
                                                                    'Document colors': '選択された色',
                                                                },
                                                            }
                                                        }
                                                    }}
                                                    onChange={(event: any, editor: any) => {
                                                        const data = editor.getData()
                                                        onChange(data)
                                                    }}
                                                    onReady={(editor: any) => {
                                                        editor?.ui?.getEditableElement()?.parentElement?.insertBefore(
                                                            editor.ui.view.toolbar.element as Node,
                                                            editor.ui.getEditableElement() as Node
                                                        );

                                                        editor.editing.view.change((writer: any) => {
                                                            writer.setStyle({
                                                                height: '260px',
                                                            }, editor.editing.view.document.getRoot())
                                                        });
                                                    }}
                                                />
                                            </Typography>
                                            {error ? (
                                                <FormHelperText
                                                    error
                                                >
                                                    {error.message}
                                                </FormHelperText>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    )}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12} sx={{ mt: 3, mb: 3}}>
                            {componentAttachmentFile()}
                        </Grid>
                    </Grid>
                    
                    <Grid xs={12}>
                        <Box sx={styles.footerModal}>
                            <ButtonCustom 
                                child={'戻る'} 
                                onClick={() => {
                                    navigate(`/finalize/${sid}/step2`,{state: { adjustmentData: selectedAdjustment }})
                                    reset()
                                    setFileAttachment([])
                                    setFilesData([])
                                    setErrorFile('')
                                }} 
                                sx={{ 
                                    fontSize: {
                                        xs: '12px',
                                        sm: '15px',
                                    },
                                }}
                            />
                            <ButtonCustom
                                isSubmit
                                sx={{
                                    minWidth: '186px !important',
                                    fontSize: {
                                        xs: '12px',
                                        sm: '15px',
                                    },
                                }}
                                child={'確定'}
                                onClick={handleSubmit(handleFormSubmit)}
                                onMouseDown={() => {
                                    if (
                                        getValues('email_subject').length === 0 ||
                                        getValues('email_subject').length > 100 ||
                                        getValues('email_body').length === 0 ||
                                        getValues('email_body').length > 5000
                                    ) {
                                        showSnackBar(
                                            true,
                                            '入力エラーがあります。修正して再度実行ボタンをクリックしてください。',
                                            'error',
                                        )
                                    }
                                }}
                                disabled={isLoading}
                            />
                        </Box>
                    </Grid>
                    </Box>
                </Grid>
            </Box>
            </Paper>
        </Container>
    )
}

export default FinalizeStep3

const styles = {
    paper: {
        p: { xs: '20px', sm: '30px', md: 5 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: ' 0px 4px 120px rgba(0, 0, 0, 0.25)',
        color: '#000000',
        marginTop: '28px',
        borderRadius: '20px',
        border: '1px solid transparent',
    },
    label: {
        lineHeight: 1.4,
        fontSize: '15px',
        height: '21px',
        margin: 'auto 0',
    },
    container: {
        alignSelf: 'center',
    },
    title: {
        fontWeight: '500',
        fontSize: '15px',
        alignItem: 'center',
        whiteSpace: 'nowrap',
        height: '21px',
        margin: 'auto 0',
    },
    adjustmentTitle: {
        pb: '20px',
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
        height: '100%',
        whiteSpace: 'nowrap',
        borderRadius: '20px',
    },
    adjustmentInfo: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '7px',
        paddingLeft: {
            xs: '0px',
            sm: '20px',
        },
    },
    boxScheduleEventMenu: {
        color: '#FFF',
        borderRadius: '10px',
        p: '0px 10px',
        display: 'flex',
        alignItems: 'center',
    },
    boxScheduleEventMenuText: {
        fontSize: '12px',
        fontWeight: '500',
    },
    boxAddInvitee: {
        display: 'flex',
        cursor: 'pointer',
        gap: '8px',
    },
    boxAddInviteeIcon: {
        fontSize: '21px',
    },
    tableContainer: {
        pt: '20px',
    },
    table: {
        th: {
            ...tableHeaderText,
            ...tableRowAlign,
            borderBottom: '1px solid #666666',
        },
        'tbody > tr': {
            cursor: 'pointer',
        },
        td: {
            ...tableBodyText,
            ...tableRowAlign,
            borderBottom: '1px solid #56577A',
        },
        marginBottom: 0,
    },
    modalBoxWrapper: {
        maxHeight: '90vh',
        overflowY: 'auto',
        p: { xs: '20px', sm: '30px', md: 5 },
        borderRadius: '16px',
        width: '700px',
        bgcolor: '#fff',
        margin: 'auto',
        border: 'none',
        '@media (max-width: 768px)': {
            width: '90%',
        },
    },
    footerModal: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row' },
}
