import { Box, Grid, IconButton, Modal, Paper, Stack, Typography } from "@mui/material"
import dayjs from "dayjs"
import { CONSTANTS } from "src/config/constants"
import { getSessionCandidateTimeSelected } from '../../../helpers/helpers'
import React, { useEffect, useState } from 'react'
import ButtonCustom from 'src/components/Common/ButtonCustom'
import CopyIcon from "src/components/Icons/CopyIcon"
import { useSnackBar } from "src/components/Common/SnackBarContext"
import { v4 as uuidv4 } from 'uuid';
import scheduleApi from "src/api/scheduleApi"
import { useNavigate } from "react-router-dom"
import { Schedule, Status } from "src/models"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { selectFilterSchedules, topActions } from "src/pages/Top/topSlice"
import adjustmentApi from "src/api/adjustmentApi"
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'

export interface ExternalUserModalProps {
    isOpen: boolean
    isLoading: boolean
    onClose: () => void,
    scheduleData: Schedule,
    onNextBtnClick: () => void,
    adjustmentData: any,
    isEditting: boolean,
    filter: any,
}

export default function SingleExternalUserModal({
    isOpen,
    isLoading,
    onClose,
    scheduleData,
    onNextBtnClick,
    adjustmentData,
    isEditting,
    filter,
}: ExternalUserModalProps) {
    const [open, setOpen] = useState(isOpen);
    const data = Object.values(getSessionCandidateTimeSelected());
    const { showSnackBar } = useSnackBar();
    const [externalHashURL, setExternalHashURL] = useState('');
    const [hash, setHash] = useState(uuidv4());
    const navigate = useNavigate();
    const filterSchedules = useAppSelector(selectFilterSchedules);
    const [adjusmentSelectedData, setAdjusmentSelectedData] = useState([]);
    const dispatch = useAppDispatch();
    const [filterSearch, setFilterSearch] = useState<any>({});

    useEffect(() => {
        let url = `${process.env.REACT_APP_EXTERNAL_SINGLE_URL}${hash}`;
        if (scheduleData && scheduleData.hash) {
            setHash(scheduleData.hash);
            url = `${process.env.REACT_APP_EXTERNAL_SINGLE_URL}${scheduleData.hash}`;
        }
        setExternalHashURL(url);
    }, [scheduleData])

    useEffect(() => {
        setFilterSearch(filter);
    }, [filter]);

    useEffect(() => {
        setAdjusmentSelectedData(adjustmentData);
    }, [adjustmentData])

    useEffect(() => {
        if (isOpen) {
            updateEventWithExternalUser(false, true);
        }

        setOpen(isOpen)
    }, [isOpen])

    const handleClose = () => {
        onClose()
    }

    const handleFormSubmit = () => {
        if (isEditting) {
            onNextBtnClick();
            return;
        }

        if (!data || data.length === 0) {
            showSnackBar(true, '最低ひとつの候補日時を選択してください。', 'error');
            return;
        }

        //Saved to DB and redirect to step 3
        updateEventWithExternalUser(true);
        onNextBtnClick();
    }

    const handleCloseExternalDialog = () => {
        if (isEditting) {
            navigate('/schedule-list', {state: {admin_id: filterSchedules.admin_id, participants: filterSchedules.participants, status: Status.ADJUSTING}})    
            return;
        }

        if (!data || data.length === 0) {
            showSnackBar(true, '最低ひとつの候補日時を選択してください。', 'error');
            onClose();
            return;
        }

        //Storage adjustment
        if (Array.isArray(adjusmentSelectedData) && adjusmentSelectedData.length > 0) {
            adjustmentApi
                .create(adjusmentSelectedData)
                .catch((err: any) => {
                    console.log(err)
                })
            updateEventWithExternalUser(false);
        }
        onClose();
    }

    const updateEventWithExternalUser = (isNext: boolean, init: boolean = false) => {
        const input = {
            hash: hash,
            is_completed: !isNext ? true : false,
        }

        if (!scheduleData) {
            showSnackBar(true, 'メールの送信に失敗しました', 'error');
            return;
        }

        if (isEditting) return;

        scheduleApi
            .updateExternalEvent(scheduleData.id, input)
            .then((res: any) => {
                if (!isNext && !init) {
                    dispatch(topActions.setScheduleNewUpdate(res.schedule))
                    showSnackBar(true, `予定「${scheduleData.subject}」を登録しました。`, 'success')
                    navigate('/schedule-list', {state: {admin_id: filterSchedules.admin_id, participants: filterSchedules.participants, status: Status.ADJUSTING}})    
                }
            })
            .catch((err: any) => {
                showSnackBar(true, 'エラーが発生しました', 'error')
                console.log(err)
            })
    }

    const handleCopy = () => {
        const textToCopy = `${externalHashURL}`;
    
        // Copy vào clipboard
        navigator.clipboard.writeText(textToCopy.trim());
        
        // Hiển thị thông báo thành công
        showSnackBar(true, `候補日と調整URLをコピーしました。`, 'success');
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{ overflow: 'auto', display: "flex", fontSize: '14px' }}
        >
            <Box sx={styles.modalBoxWrapper}>
                <Grid container>
                    <Grid item xs={12}>
                        <Stack spacing={1} sx={{ mb: '5px' }}>
                            社外のスケジュール調整を開始します。
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1} sx={{ mt: 3, mb: '5px' }}>
                            メールやslack等で直接依頼をかける場合は、以下よりURLをコピーしてください。
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1} sx={{ mt: 3, mb: '5px'}}>
                            <Paper variant="outlined" sx={styles.copyBox} onClick={handleCopy}>
                                <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
                                    <b>■日程調整URL</b><br />
                                    {externalHashURL}
                                </Typography>
                                <Box sx={styles.copyIconBox}>
                                    <ContentCopyRoundedIcon sx={styles.copyIcon} />
                                    <Box sx={styles.copyText}>コピー</Box>
                                </Box>
                            </Paper>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack spacing={1} sx={{ mt: 3, mb: '5px' }}>
                            このままDyskeで調整依頼をかける場合は、「次へ」ボタンをクリックしてください。
                        </Stack>
                    </Grid>
                </Grid>
                
                <Grid>
                    <Box sx={styles.footerModal}>
                        <ButtonCustom 
                            child={'閉じる'} 
                            onClick={handleCloseExternalDialog}
                        />
                        <ButtonCustom
                            isSubmit
                            sx={{ minWidth: '186px !important' }}
                            child={'次へ'}
                            onClick={handleFormSubmit}
                            disabled={isLoading}
                        />
                    </Box>
                </Grid>
            </Box>
        </Modal>
    )
}

const styles = {
    form: {
        mt: 2,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        width: '100%',
        p: { xs: '20px', sm: '30px', md: 5 },
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0px 4px 120px rgba(0, 0, 0, 0.25)',
        borderRadius: 5,
    },
    label: {
        lineHeight: 1.4,
        fontSize: '15px',
        height: '21px',
        margin: 'auto 0',
    },
    container: {
        alignSelf: 'center',
    },
    title: {
        fontWeight: '500',
        fontSize: '15px',
        alignItem: 'center',
        whiteSpace: 'nowrap',
        height: '21px',
        margin: 'auto 0',
    },
    modalBoxWrapper: {
        maxHeight: '90vh',
        overflowY: 'auto',
        p: { xs: '20px', sm: '30px', md: 5 },
        borderRadius: '16px',
        width: '700px',
        bgcolor: '#fff',
        margin: 'auto',
        border: 'none',
        '@media (max-width: 768px)': {
            width: '90%',
        },
    },
    footerModal: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        marginTop: '40px',
    },
    copyBox: {
        p: 2,
        position: 'relative',
        mb: 2,
        borderRadius: '15px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#fafafa',
        },
    },
    copyIconBox: {
        position: 'absolute',
        top: 14,
        right: 14,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginBottom: '5px',
    },
    copyText: {
        whiteSpace: 'nowrap',
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '21px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#000000',
    },
    copyIcon: {
        fontSize: '18px',
        color: '#000000',
    }
}
