import { useEffect, useState, useRef } from 'react'
import { CANDIDATE_TIME_SESSION_KEY, CONSTANTS } from 'src/config/constants'
import {
    Box,
    useTheme,
    Container,
    Paper,
    Typography,
    Stack,
    MenuItem,
    FormHelperText,
    ListItemText,
    Select,
    Button,
    debounce,
    Radio,
    FormControlLabel,
    RadioGroup,
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Grid from '@mui/material/Unstable_Grid2'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'
import moment, { Moment } from 'moment' // using
import 'moment/locale/ja'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import {
    adjustmentActions,
    selectFilterInternalUsers,
    selectInfoSchedule,
    selectInternalUsers,
    selectLoadingUpdateSchedule,
    selectOrganizations,
    selectScheduleCreatedData,
    selectloadingCreateSchedule,
    selectLoadingFetchScheduleById,
    selectAlertMessage,
    selectAlertSeverity,
    selectShowAlert,
    selectLoadingSchedule,
} from '../adjustmentSlice'
import { selectCurrentUser } from '../../Auth/authSlice'
import ComponentStepper from '../components/Stepper'
import SelectCustom from 'src/components/SelectCustom'
import { TextInput } from 'src/components/TextInput'
import { ArrowLeftIcon } from 'src/components/Icons'
import { labelOfInput, labelOfInputAlign, inputField, buttonSubmitStyle } from 'src/theme'
import ButtonCustom from 'src/components/Common/ButtonCustom'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import adjustmentApi from 'src/api/adjustmentApi'
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { replaceGaroonName } from '../../../helpers/helpers'
import useWindowDimensions from 'src/hook/useWindowDimensions'
import { getSelectorsByUserAgent } from 'react-device-detect'
import { groupActions, selectFilterMyGroup, selectMyGroups } from 'src/pages/Group/groupSlice'
import CloseIcon from '@mui/icons-material/Close';

const AdjustmentSingleExternalStep1 = () => {
    const { sid } = useParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const theme = useTheme()

    const userAgent = window.navigator.userAgent
    const {isMobile}  = getSelectorsByUserAgent(userAgent)

    const organizationsList = useAppSelector(selectOrganizations)
    const internalUsers = useAppSelector(selectInternalUsers)
    const filterInternalUsers = useAppSelector(selectFilterInternalUsers)
    const infoSchedule = useAppSelector(selectInfoSchedule)
    const loadingCreateSchedule = useAppSelector(selectloadingCreateSchedule)
    const loadingFetchScheduleById = useAppSelector(selectLoadingFetchScheduleById)
    const scheduleCreatedData = useAppSelector(selectScheduleCreatedData)
    const loadingUpdateSchedule = useAppSelector(selectLoadingUpdateSchedule)
    const currentUser = useAppSelector(selectCurrentUser)
    const loadingSchedule = useAppSelector(selectLoadingSchedule)

    const [leftDataSelected, setLeftDataSelected] = useState<any[]>([])
    const [rightDataSelected, setRightDataSelected] = useState<any[]>([])
    const [errorBoxLeft, setErrorBoxLeft] = useState<boolean>(false)
    const [scheduleTags, setScheduleTags] = useState<any>([])

    const showAlert = useAppSelector(selectShowAlert)
    const alertMessage = useAppSelector(selectAlertMessage)
    const alertSeverity = useAppSelector(selectAlertSeverity)
    const [startDateSelect, setStartDate] = useState<Moment | null>(moment());
    const [endDateSelect, setEndDate] = useState<Moment | null>(moment().add(3, 'weeks'));
    const { height, width } = useWindowDimensions();
    const [updateOrganizationsList, setUpdateOrganizationsList] = useState<any>(organizationsList)
    const [scheduleDataUpdate, setScheduleDataUpdate] = useState<any>(null)
    const location = useLocation();
    const dataLocation = location.state?.dataLocation;

    const [periodType, setPeriodType] = useState<number>(CONSTANTS.PERIOD_TYPE.FIXED)

    // DATE PICKER CUSTOM
    const dateRangePickerRef = useRef<any>()
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [tempStartDate, setTempStartDate] = useState<Moment | null>(null);
    const [tempEndDate, setTempEndDate] = useState<Moment | null>(null);
    const handleDatesChange = ({ startDate, endDate }: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
        if (endDateSelect != endDate) {
            setFocusedInput('startDate');
        }
        setStartDate(startDate);
        setEndDate(endDate);
    };
    const { showSnackBar } = useSnackBar()
    const orientation = width < 600 ? 'vertical' : 'horizontal';
    const calendarNumberOfMonths = width < 600 ? 12 : 2;
    const calendarFullScreen = width < 600 ? true : false;
    const calendarKeepOpen = width < 600 ? true : false;
    const calendarCustomCloseIcon = width < 600 ? (<CloseIcon onClick={() => setShowDatePicker(false)}/>) : null;
    const calendarInfoPosition = width < 600 ? 'bottom' : undefined;
    const renderCalendarInfo = () => {
        if (width < 600) {
            return (
                <Box className='DayPickerCustomBtn'>
                    <Button className='DayPickerCustomBtn_submit' onClick={handleApplyDatePicker}>選択完了</Button>
                </Box>
            );
        }
    }

    const handleBoxClick = () => {
        if (currentUser?.is_employee && infoSchedule.status === "ADJUSTING"  && dataLocation === undefined && sid ) {
            setShowDatePicker(false);
            return;
        }
        if (width < 600) {
            setShowDatePicker(true);
        }
    };

    const handleApplyDatePicker = () => {
        if (width < 600) {
            setTempStartDate(startDateSelect);
            setTempEndDate(endDateSelect);
            setShowDatePicker(false);
        }
    };

    const handleCloseDatePicker = ({ startDate, endDate }: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
        if (width < 600) {
            setStartDate(tempStartDate);
            setEndDate(tempEndDate);
            setShowDatePicker(true);

            document.body.style.position = '';
            document.body.style.height = '';
            document.body.style.width = '';
        }
    };

    useEffect(() => {
        getListScheduleTags()
        sessionStorage.removeItem(CANDIDATE_TIME_SESSION_KEY);
    },[])

    const groupList = useAppSelector(selectMyGroups)
    const filterGroup = useAppSelector(selectFilterMyGroup)

    useEffect(() => {
        dispatch(groupActions.fetchMyGroup(filterGroup));
    }, [filterGroup])

    useEffect(() => {
        const checkAllOrganization = organizationsList.filter((organization: any) => organization.name === CONSTANTS.ORGANIZATION_ALL.NAME);
        const newGroupList = Array.isArray(groupList) ? groupList : [];
        if (checkAllOrganization.length > 0) {
            const data = [];
            for (let i = 1; i < organizationsList.length; i++) {
                data.push({
                    id: organizationsList[i].id,
                    name: organizationsList[i].name,
                })
            }
            setUpdateOrganizationsList([...data, ...newGroupList]);
        } else {
            setUpdateOrganizationsList([...organizationsList, ...newGroupList]);
        }
    },[organizationsList])

    const getListScheduleTags = () => {
        adjustmentApi.getListScheduleTags().then((res: any) => {
            if (res.success) {
                setScheduleTags(res.data);
            }
            return res;
        })
        .catch((err) => {return []})
        .finally(() => {})
    }

    useEffect(() => {
        reset({
            adjustment_period: 3,
            event_menu: 'オンライン_社外',
            subject: '',
            note: '',
            condition_of_internal_user: 'or',
            period_type: CONSTANTS.PERIOD_TYPE.FIXED,
            adjustment_date: [
                moment().format('YYYY/M/D'),
                moment().add(3, 'weeks').format('YYYY/M/D')
            ],
        })

        if (sid) {
            dispatch(adjustmentActions.fetchScheduleById(Number(sid)))
        } else {
            if (currentUser) {
                dispatch(
                    adjustmentActions.setInfoSchedule({
                        ...infoSchedule,
                        subject: '',
                        event_type: CONSTANTS.EVENT_TYPE.REGULAR,
                        event_menu: '---',
                        condition_of_internal_user: 'or',
                        note: '',
                        adjustment_period: 3,
                        period_type: CONSTANTS.PERIOD_TYPE.FIXED,
                        adjustment_date: [
                            moment().format('YYYY/M/D'),
                            moment().add(3, 'weeks').format('YYYY/M/D')
                        ],
                        participants: [
                            {
                                id: Number(currentUser.id),
                                name: currentUser.name,
                                sur_name: currentUser.sur_name,
                                given_name: currentUser.given_name,
                            },
                        ],
                        adjustment_start_date: undefined,
                        adjustment_end_date: undefined,
                        facilities: [],
                    }),
                )
            }
        }
    }, [sid, currentUser])

    useEffect(() => {
        if (loadingCreateSchedule === false && showAlert === false && scheduleDataUpdate) {
            navigate(`/adjustment/step2/${scheduleCreatedData.id}`, { state: { dataLocation: {is_creating: true} } })
            dispatch(adjustmentActions.setLoadingCreateSchedule(true))
            dispatch(adjustmentActions.setLoadingSchedule(false))
        }
    }, [loadingCreateSchedule])

    useEffect(() => {
        if (loadingUpdateSchedule === false && showAlert === false && scheduleDataUpdate) {
            if (dataLocation !== undefined || scheduleDataUpdate?.status === 'DRAFT') {
                navigate(`/adjustment/step2/${sid}`, { state: { dataLocation: {is_creating: true} } })
            } else {
                navigate(`/adjustment/step2/${sid}`)
            }
            dispatch(adjustmentActions.setLoadingUpdateSchedule(true))
            dispatch(adjustmentActions.setLoadingSchedule(false))
        }
    }, [loadingUpdateSchedule])

    useEffect(() => {
        if (showAlert && alertMessage !== '') {
            showSnackBar(true, alertMessage, alertSeverity)
        }
    }, [showAlert])

    useEffect(() => {
        if (loadingFetchScheduleById === false && sid) {
            const startDate = dayjs(infoSchedule.adjustment_start_date);
            const endDate = dayjs(infoSchedule.adjustment_end_date);
            setStartDate(moment(startDate.format()));
            setEndDate(moment(endDate.format()));
            reset({
                ...infoSchedule,
                adjustment_period: infoSchedule.adjustment_period,
                adjustment_date: [dayjs(infoSchedule.adjustment_start_date), dayjs(infoSchedule.adjustment_end_date)],
                period_type: infoSchedule.period_type,
            })
            setPeriodType(infoSchedule.period_type)
        }
    }, [loadingFetchScheduleById])

    useEffect(() => {
        dispatch(adjustmentActions.fetchOrganizations())
    }, [])

    useEffect(() => {
        dispatch(adjustmentActions.fetchInternalUsers(filterInternalUsers))
    }, [filterInternalUsers])

    const handleClickItem = (frame: string, user: any, selected: boolean) => {
        if (frame === 'left') {
            if (selected) {
                setLeftDataSelected((data) => data.filter((e) => e.id !== user.id))
            } else {
                setLeftDataSelected((data) => [...data, user])
            }
        } else if (selected) {
            setRightDataSelected((data) => data.filter((e) => e.id !== user.id))
        } else {
            setRightDataSelected((data) => [...data, user])
        }
    }

    const handleArrow = (to: string) => {
        if (to === 'left') {
            if (rightDataSelected.length === 0) {
                return
            }
            // add users selected right to left
            dispatch(
                adjustmentActions.setInfoSchedule({
                    ...infoSchedule,
                    participants: [...infoSchedule.participants, ...rightDataSelected],
                }),
            )
            setErrorBoxLeft(false)
            setRightDataSelected([])

            setRightDataUserSelected([])
            setLeftDataUserSelected([])
        } else {
            if (leftDataSelected.length === 0) {
                return
            }
            if (leftDataSelected.length === infoSchedule.participants.length) {
                setErrorBoxLeft(true)
            }
            // remove users selected left
            dispatch(
                adjustmentActions.setInfoSchedule({
                    ...infoSchedule,
                    participants: [
                        ...infoSchedule.participants.filter((e: any) => !leftDataSelected.some((i) => i.id === e.id)),
                    ],
                }),
            )
            setLeftDataSelected([])

            setRightDataUserSelected([])
            setLeftDataUserSelected([])
        }
    }

    const onChangeTextSearchInternalUsers = (text: string) => {
        dispatch(
            adjustmentActions.setFilterInternalUser({
                ...filterInternalUsers,
                text,
            }),
        )
    }

    useEffect(() => {
        dispatch(adjustmentActions.fetchFacilityGroups())
    }, [])

    const schema = yup.object({
        adjustment_period: yup
            .number()
            .required('必ず入力してください。'),
        event_menu: yup
            .string()
            .required('必ず入力してください。')
            .test('validate_event_menu', '必ず入力してください。', function (value) {
                if (value) {
                    return value !== '---'
                }
                return true
            }),
        subject: yup.string().max(50, '50文字以内で入力してください。').required('必ず入力してください。'),
        note: yup.string(),
        condition_of_internal_user: yup.string().required('必ず入力してください。'),
        period_type: yup.number().required('必ず入力してください。'),
        adjustment_date: yup
            .array()
            .when('period_type', {
                is: CONSTANTS.PERIOD_TYPE.FIXED,
                then: (schema) => schema.test(
                    'validate_valid_adjustment_date', 
                    '調整期間を指定してください', 
                    function (value: any) {
                        return (endDateSelect instanceof moment) && (startDateSelect instanceof moment)
                    }
                ),
                otherwise: (schema) => schema
            }),
    })

    const {
        control,
        handleSubmit,
        getValues,
        setError,
        setValue,
        reset,
        formState: { isSubmitting, errors, isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            event_menu: 'オンライン社外',
            subject: '',
            note: '',
            condition_of_internal_user: 'or',
            adjustment_period: 3,
            period_type: CONSTANTS.PERIOD_TYPE.FIXED,
            adjustment_date: [
                moment().format('YYYY/M/D'),
                moment().add(3, 'weeks').format('YYYY/M/D')
            ],
        },
        resolver: yupResolver(schema),
    })

    const handleFormSubmit = (data: any) => {
        if (infoSchedule.participants.length === 0) {
            showSnackBar(true, '入力エラーがあります。修正して再度実行ボタンをクリックしてください。', 'error')
            setErrorBoxLeft(true)
            return
        }

        let adjustmentStartDate = ''
        let adjustmentEndDate = ''
        if (data.period_type === CONSTANTS.PERIOD_TYPE.FIXED) {
            if (!startDateSelect || !endDateSelect) {
                showSnackBar(true, '入力エラーがあります。修正して再度実行ボタンをクリックしてください。', 'error')
                return
            }
            adjustmentStartDate = startDateSelect.format('YYYY/M/D 00:00:00')
            adjustmentEndDate = endDateSelect.format('YYYY/M/D 23:59:59')
        } else {
            adjustmentStartDate = dayjs().format('YYYY/M/D 00:00:00')
            adjustmentEndDate = dayjs().add(data.adjustment_period, 'week').format('YYYY/M/D 23:59:59')
        }
        let dataSchedule = {
            ...infoSchedule,
            participants: [...infoSchedule.participants.map((e: any) => e.id)],
            adjustment_start_date: adjustmentStartDate,
            adjustment_end_date: adjustmentEndDate,
            event_menu: data.event_menu,
            subject: data.subject,
            note: data.note,
            condition_of_internal_user: data.condition_of_internal_user,
            adjustment_period: data.adjustment_period,
            type: CONSTANTS.TYPE_ADJUSTMENT.SINGLE_EXTERNAL,
            period_type: data.period_type,
            status: infoSchedule?.status === "ADJUSTING"  ? infoSchedule.status : 'DRAFT',
        }
        setScheduleDataUpdate(dataSchedule)
        if (sid) {
            dataSchedule = {
                ...dataSchedule,
                id: Number(sid),
            }
            dispatch(adjustmentActions.updateSchedule(dataSchedule))
        } else {
            dispatch(adjustmentActions.createSchedule(dataSchedule))
        }
    }

    const [rightDataUserSelected, setRightDataUserSelected] = useState<any>([])
    const [leftDataUserSelected, setLeftDataUserSelected] = useState<any>([])

    const handleChangeRightDataUser = (event: any) => {
        setLeftDataUserSelected([])
        setLeftDataSelected([])
        let options = event.target.options;
        let value = [];
        let user = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                user.push(JSON.parse(options[i].getAttribute('data-user')))
            }
        }
        setRightDataSelected(user)
        setRightDataUserSelected(value)
    }

    const handleChangeLeftDataUser = (event: any) => {
        setRightDataUserSelected([])
        setRightDataSelected([])
        let options = event.target.options;
        let value = [];
        let user = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                user.push(JSON.parse(options[i].getAttribute('data-user')))
            }
        }
        setLeftDataUserSelected(value)
        setLeftDataSelected(user)
    }

    const isDisabled = currentUser?.is_employee && infoSchedule.status === "ADJUSTING"  && dataLocation === undefined && sid

    return (
        <Container sx={{ p: '0 !important' }}>
            <Box>
                <Grid container spacing={4}>
                    <Grid xs={12} sx={{ mt: '12px' }}>
                        <ComponentStepper activeStep={0} />
                    </Grid>
                </Grid>
            </Box>
            <Paper elevation={3} sx={styles.paper}>
                <Box component="form" noValidate>
                    <Grid container spacing={3}>
                        <Grid xs={12} sm={5.5} md={4}>
                            <Stack direction={'column'} spacing={1}>
                                <Typography sx={styles.label}>予定</Typography>
                                <Controller
                                    name="event_menu"
                                    control={control}
                                    defaultValue={getValues('event_menu')}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Box sx={styles.scheduleTagSelect}>
                                            <SelectCustom value={value} onChange={onChange} error={error} sx={styles.scheduleTagMenu}>
                                                <MenuItem key={0} value={'---'}>
                                                    <ListItemText primary={'---'} />
                                                </MenuItem>
                                                {scheduleTags.map((item: any) => (
                                                    <MenuItem key={item.id} value={item.name} sx={styles.scheduleTagMenuItem}>
                                                        <Box sx={[styles.squareBox, { backgroundColor: item.color }]}></Box>
                                                        <ListItemText primary={item.name} sx={{'& .MuiTypography-root' : {fontSize: '15px' }}}/>
                                                    </MenuItem>
                                                ))}
                                            </SelectCustom>
                                            {error && (
                                                <FormHelperText error sx={styles.error}>
                                                    {error.message}
                                                </FormHelperText>
                                            )}
                                        </Box>
                                    )}
                                />
                            </Stack>
                        </Grid>

                        <Grid xs={12} sm={6.5} md={8}>
                            <Stack direction={'column'} spacing={1}>
                                <Typography sx={styles.label}>予定タイトル</Typography>
                                <Controller
                                    name="subject"
                                    control={control}
                                    defaultValue={getValues('subject')}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Box sx={{ position: 'relative' }}>
                                            <TextInput
                                                value={value}
                                                autoComplete="off"
                                                onChange={onChange}
                                                placeholder=" 例）DYSKE完成レビュー会"
                                                error={!!error}
                                                required
                                            />
                                            <FormHelperText error sx={styles.error}>
                                                {error ? error.message : ''}
                                            </FormHelperText>
                                        </Box>
                                    )}
                                />
                            </Stack>
                        </Grid>

                        <Grid xs={12}>
                            <Stack spacing={1} sx={{ position: 'relative' }}>
                                <Typography sx={styles.label}>オンラインMTG URL</Typography>
                                <Controller
                                    name="note"
                                    control={control}
                                    defaultValue={getValues('note')}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Box sx={{ position: 'relative' }}>
                                            <TextInput
                                                value={value}
                                                autoComplete="off"
                                                onChange={onChange}
                                                placeholder=" 例）https://us06web.zoom.us/j/xxxxx"
                                                error={!!error}
                                            />
                                            <FormHelperText error sx={styles.error}>
                                                {error ? error.message : ''}
                                            </FormHelperText>
                                        </Box>
                                    )}
                            />
                            </Stack>
                        </Grid>

                        <Grid xs={12}>
                            <Typography sx={[styles.label, { mb: 1 }]}>調整期間</Typography>
                            <Stack direction="row"  sx={{
                                flexWrap: {xs: 'wrap', '@media (min-width: 885px)': {
                                    flexWrap: 'nowrap'
                                }},
                            }}>
                                <Controller
                                    name="period_type"
                                    control={control}
                                    defaultValue={getValues('period_type')}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Box sx={[styles.scheduleTagSelect, {
                                                marginLeft: '8px', 
                                                minWidth: 'fit-content',
                                                '@media (max-width: 884px)': {
                                                    marginBottom: '16px'
                                                }
                                            }]}>
                                            <RadioGroup
                                                row
                                                aria-labelledby="period_type"
                                                name="period_type"
                                                value={value}
                                                onChange={(event) => {
                                                    onChange(event)
                                                    setPeriodType(parseInt(event.target.value))
                                                }}
                                                sx={{
                                                    flexWrap: 'nowrap',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                <FormControlLabel value={CONSTANTS.PERIOD_TYPE.FIXED} control={<Radio />} label="期間を固定する" disabled={isDisabled} sx={styles.formRadioLabel}/>
                                                <FormControlLabel value={CONSTANTS.PERIOD_TYPE.UNFIXED} control={<Radio />} label="期間を固定しない" disabled={isDisabled} sx={styles.formRadioLabel}/>
                                            </RadioGroup>
                                            {error && (
                                                <FormHelperText error sx={styles.error}>
                                                    {error.message}
                                                </FormHelperText>
                                            )}
                                        </Box>
                                    )}
                                />
                                
                                { periodType === CONSTANTS.PERIOD_TYPE.FIXED && (
                                <Controller
                                    name="adjustment_date"
                                    control={control}
                                    defaultValue={getValues('adjustment_date')}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => {
                                        if (dateRangePickerRef.current) {
                                            dateRangePickerRef.current.setStartDate(dayjs(value[0]).format('YYYY/M/D'))
                                            dateRangePickerRef.current.setEndDate(dayjs(value[1]).format('YYYY/M/D'))
                                        }
                                        return (
                                            <Box sx={{ position: 'relative', marginLeft: '80px !important',
                                                '@media (max-width: 884px)': {
                                                    marginLeft: '0px !important'
                                                },
                                                '@media (max-width: 600px)': {
                                                    width: '100%'
                                                }
                                            }}>
                                                <Box
                                                    sx={[
                                                            styles.targetPeriod,
                                                            {
                                                                borderColor: error
                                                                    ? '#f44336 !important'
                                                                    : '#666 !important',
                                                            },
                                                            currentUser?.is_employee && infoSchedule.status === "ADJUSTING" && dataLocation === undefined && sid && {
                                                                backgroundColor: "#eaeaea",
                                                                cursor:"not-allowed",
                                                                '& input': {
                                                                    cursor:"not-allowed",
                                                                },
                                                                '& .DateInput_input__disabled': {
                                                                    fontStyle: "normal",
                                                                    color: "#070707"
                                                                }
                                                            }
                                                        ]}
                                                    >
                                                        { !showDatePicker && width < 600 && (
                                                            <Box sx={[{ width: '100%', display: 'flex', textAlign: 'center', fontSize: '16px', fontWeight: '400' }, currentUser?.is_employee && infoSchedule.status === "ADJUSTING"  && dataLocation === undefined && sid && {color: '#070707'}]} onClick={handleBoxClick}>
                                                                <Box sx={{ width: '48%' }}>{startDateSelect ? moment(startDateSelect).format('YYYY/M/D') : ''}</Box>
                                                                <Box sx={{ width: '4%' }}>ー</Box>
                                                                <Box sx={{ width: '48%' }}>{endDateSelect ? moment(endDateSelect).format('YYYY/M/D') : ''}</Box>
                                                            </Box>
                                                        )}
                                                        {(showDatePicker || width > 600) && (
                                                            <DateRangePicker
                                                                disabled={currentUser?.is_employee && infoSchedule.status === "ADJUSTING"  && dataLocation === undefined && sid}
                                                                dayPickerNavigationInlineStyles={[currentUser?.is_employee && infoSchedule.status === "ADJUSTING" && sid && {fontStyle: "normal"}]}
                                                                startDate={startDateSelect} // momentPropTypes.momentObj or null,
                                                                endDate={endDateSelect} // momentPropTypes.momentObj or null,
                                                                startDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                                                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                                                onDatesChange={handleDatesChange} // PropTypes.func.isRequired,
                                                                focusedInput={focusedInput}
                                                                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                                                                hideKeyboardShortcutsPanel={true}
                                                                customArrowIcon={'ー'}
                                                                displayFormat="YYYY/M/D"
                                                                monthFormat="YYYY年 M月"
                                                                readOnly={true}
                                                                startDatePlaceholderText={''}
                                                                endDatePlaceholderText={''}
                                                                minimumNights={0}
                                                                orientation={orientation}
                                                                numberOfMonths={calendarNumberOfMonths}
                                                                withFullScreenPortal={calendarFullScreen}
                                                                customCloseIcon = {calendarCustomCloseIcon}
                                                                keepOpenOnDateSelect={calendarKeepOpen}
                                                                renderCalendarInfo={renderCalendarInfo}
                                                                calendarInfoPosition={calendarInfoPosition}
                                                                onClose={handleCloseDatePicker} // Handle closing
                                                                initialVisibleMonth={() => moment()}
                                                            />
                                                        )}
                                                    </Box>
                                                <FormHelperText sx={styles.error} error>
                                                    {error ? error.message : ''}
                                                </FormHelperText>
                                            </Box>
                                        )
                                        }}
                                    />
                                )}

                                { periodType === CONSTANTS.PERIOD_TYPE.UNFIXED && (
                                    <Controller
                                        name="adjustment_period"
                                        control={control}
                                        defaultValue={getValues('adjustment_period')}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Box sx={[styles.scheduleTagSelect, {
                                            width: '260px',
                                            marginLeft: '80px !important',
                                            '@media (max-width: 884px)': {
                                                marginLeft: '30px !important'
                                            },
                                            '@media (max-width: 780px)': {
                                                marginLeft: '0px !important'
                                            },
                                            '@media (max-width: 600px)': {
                                                width: '50%'
                                            }
                                        }]}>
                                            <SelectCustom value={value} onChange={onChange} error={error} sx={styles.scheduleTagMenu} disabled={isDisabled}>
                                                {CONSTANTS.SINGLE_EXTERNAL_PERIOD.map((item: any) => (
                                                    <MenuItem key={item.value} value={item.value}>
                                                        <ListItemText primary={item.label} />
                                                    </MenuItem>
                                                ))}
                                            </SelectCustom>
                                            {error && (
                                                <FormHelperText error sx={styles.error}>
                                                    {error.message}
                                                </FormHelperText>
                                                )}
                                            </Box>
                                        )}
                                    />
                                )}
                            </Stack>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid xs={12} sx={{ paddingBottom: '0px'}}>
                                <Typography sx={styles.label}>参加者</Typography>
                            </Grid>
                            <Grid xs={12} sx={{ marginBottom: '-20px' }}>
                                <Controller
                                    name="condition_of_internal_user"
                                    control={control}
                                    defaultValue={getValues('condition_of_internal_user')}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <Box sx={[styles.scheduleTagSelect, {marginLeft: '8px'}]}>
                                            <RadioGroup
                                                row
                                                aria-labelledby="condition_of_internal_user"
                                                name="condition_of_internal_user"
                                                value={value}
                                                onChange={onChange}
                                            >
                                                <FormControlLabel value="or" control={<Radio />} label="OR" disabled={isDisabled} sx={styles.formRadioLabel}/>
                                                <FormControlLabel value="and" control={<Radio />} label="AND" disabled={isDisabled} sx={styles.formRadioLabel}/>
                                            </RadioGroup>
                                            {error && (
                                                <FormHelperText error sx={styles.error}>
                                                    {error.message}
                                                </FormHelperText>
                                            )}
                                        </Box>
                                    )}
                                />
                            </Grid>
                            <Stack 
                                sx={{width: '100%'}} 
                                direction={{
                                    xs: 'column-reverse',
                                    sm: 'row',
                                }} 
                                spacing={0}
                            >
                                <Grid xs={12} sm={5.5}>
                                    {
                                        !isMobile ? (
                                            <Select
                                                fullWidth
                                                multiple
                                                native
                                                autoFocus={true}
                                                value={leftDataUserSelected}
                                                onChange={handleChangeLeftDataUser}
                                                sx={{
                                                    height: {
                                                        xs: '248px',
                                                        sm: '400px',
                                                    },
                                                    '& .MuiNativeSelect-select[multiple]': {
                                                        overflow: 'auto',
                                                        padding: '0px !important',
                                                        height: '100%',
                                                        borderRadius: '15px',
                                                    },
                                                    ' & option': {
                                                        ':checked, :focus, :active': {
                                                            background: 'linear-gradient(#ffff00,#ffff00) !important',
                                                            boxShadow: '0 0 10px 100px #ffff00 inset !important',
                                                            backgroundColor: '#ffff00 !important',
                                                            color: '#000000 !important',
                                                            WebkitTextFillColor: '#000000 !important',
                                                        },
                                                        ':first-of-type': {
                                                            pt: '10px',
                                                        },
                                                        ':last-of-type': {
                                                            pb: '10px',
                                                        },
                                                        padding: '4px 15px',
                                                        fontSize: '16px',
                                                    },
                                                    '&.MuiInputBase-root': {
                                                        fieldset: {
                                                            border: errorBoxLeft ? '1px solid red' : '1px solid #666',
                                                            borderRadius: '15px',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: '1px solid #666',
                                                            borderRadius: '15px',
                                                        },
                                                    },
                                                }}
                                            >
                                                {
                                                    infoSchedule.participants.map((item: any) => {
                                                        return (
                                                            <option 
                                                                key={item.id} 
                                                                value={item.id}
                                                                data-user={JSON.stringify(item)}
                                                            >
                                                                {replaceGaroonName(item, width) || item.user.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        ) : (
                                            <Box sx={[styles.boxLeft, errorBoxLeft && { border: '1px solid red' }]}>
                                                <Box 
                                                    sx={{ 
                                                        overflow: 'auto', 
                                                        height: {
                                                            xs: '248px',
                                                            sm: '400px',
                                                        }
                                                    }}
                                                >
                                                    {infoSchedule.participants.map((item: any) => {
                                                        const selected = leftDataSelected.some((i) => i.id === item.id)
                                                        return (
                                                            <Grid
                                                                key={item.id}
                                                                sx={[
                                                                    styles.userItem,
                                                                    selected && {
                                                                        backgroundColor: theme.palette.secondary.main,
                                                                    },
                                                                ]}
                                                                tabIndex={0}
                                                                onClick={() => handleClickItem('left', item, selected)}
                                                            >
                                                                {replaceGaroonName(item, width) || item.user.name}
                                                            </Grid>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                        )
                                    }
                                    <FormHelperText error sx={styles.error}>
                                        {errorBoxLeft && '少なくとも 1 人の参加者を追加してください'}
                                    </FormHelperText>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sm={1}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: {
                                                xs: 'row',
                                                sm: 'column',
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                transform: {
                                                    xs: 'rotate(270deg)',
                                                    sm: 'rotate(0deg)',
                                                },
                                                cursor: isDisabled ? 'not-allowed' : 'pointer',
                                                opacity: isDisabled ? 0.5 : 1,
                                                pointerEvents: isDisabled ? 'none' : 'auto'
                                            }}
                                            onClick={() => handleArrow('left')}
                                        >
                                            <ArrowLeftIcon />
                                        </Box>
                                        <Box
                                            sx={{
                                                transform: {
                                                    xs: 'rotate(90deg)',
                                                    sm: 'rotate(180deg)',
                                                },
                                                cursor: isDisabled ? 'not-allowed' : 'pointer',
                                                opacity: isDisabled ? 0.5 : 1,
                                                pointerEvents: isDisabled ? 'none' : 'auto'
                                            }}
                                            onClick={() => handleArrow('right')}
                                        >
                                            <ArrowLeftIcon />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={12} sm={5.5}>
                                    <Stack
                                        sx={{
                                            height: '100%',
                                            justifyContent: 'space-between',
                                            flexDirection: 'column',
                                        }}
                                        spacing={2}
                                    >
                                        <SelectCustom
                                            value={filterInternalUsers.organization_id}
                                            onChange={(event: any) => {
                                                dispatch(
                                                    adjustmentActions.setFilterInternalUser({
                                                        ...filterInternalUsers,
                                                        organization_id: event.target.value,
                                                    }),
                                                )
                                                setRightDataSelected([])
                                            }}
                                        >
                                            {updateOrganizationsList.map((organization: any, index: number) => (
                                                <MenuItem key={`${organization.id}`} value={organization.id}>
                                                    <ListItemText primary={organization.name} />
                                                </MenuItem>
                                            ))}
                                        </SelectCustom>
                                        <TextInput
                                            value={filterInternalUsers.text}
                                            autoComplete="off"
                                            onChange={(event: any) => {
                                                onChangeTextSearchInternalUsers(event.target.value)
                                                setRightDataSelected([])
                                            }}
                                            placeholder="ユーザを探す"
                                        />
                                        {
                                            !isMobile ? (
                                                <Box>
                                                    <Select
                                                        fullWidth
                                                        multiple
                                                        native
                                                        value={rightDataUserSelected}
                                                        onChange={handleChangeRightDataUser}
                                                        sx={{
                                                            height: '248px',
                                                            '& .MuiNativeSelect-select[multiple]': {
                                                                overflow: 'auto',
                                                                padding: '0px',
                                                                height: '100%',
                                                                borderRadius: '15px',
                                                            },
                                                            ' & option': {
                                                                ':checked, :focus, :active': {
                                                                    background: 'linear-gradient(#ffff00,#ffff00)',
                                                                    boxShadow: '0 0 10px 100px #ffff00 inset !important',
                                                                    backgroundColor: '#ffff00 !important',
                                                                    color: '#000000 !important',
                                                                    WebkitTextFillColor: '#000000 !important',
                                                                },
                                                                ':first-of-type': {
                                                                    pt: '10px',
                                                                },
                                                                ':last-of-type': {
                                                                    pb: '10px',
                                                                },
                                                                padding: '4px 15px',
                                                                fontSize: '16px',
                                                            },
                                                            '&.MuiInputBase-root': {
                                                                fieldset: {
                                                                    border: '1px solid #666',
                                                                    borderRadius: '15px',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: '1px solid #666',
                                                                    borderRadius: '15px',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {
                                                            internalUsers.filter((internalUsers: any) =>
                                                                !infoSchedule.participants.some(
                                                                    (e: any) => e.id === internalUsers.id,
                                                                ),).map((item: any) => {
                                                                    return (
                                                                        <option 
                                                                            key={item.id} 
                                                                            value={item.id}
                                                                            data-user={JSON.stringify(item)}
                                                                            className='option-user-right'
                                                                        >
                                                                            {replaceGaroonName(item, width)}
                                                                        </option>
                                                                    )
                                                                })
                                                        }
                                                    </Select>
                                                </Box>
                                            ) : (
                                                <Box sx={styles.boxRight}>
                                                    <Box sx={{ overflow: 'auto', height: '248px' }}>
                                                        {internalUsers
                                                            .filter(
                                                                (internalUsers: any) =>
                                                                    !infoSchedule.participants.some(
                                                                        (e: any) => e.id === internalUsers.id,
                                                                    ),
                                                            )
                                                            .map((item: any) => {
                                                                const selected = rightDataSelected.some((i) => i.id === item.id)
                                                                return (
                                                                    <Grid
                                                                        key={item.id}
                                                                        sx={[
                                                                            styles.userItem,
                                                                            selected && {
                                                                                backgroundColor: theme.palette.secondary.main,
                                                                            },
                                                                        ]}
                                                                        tabIndex={0}
                                                                        onClick={() => handleClickItem('right', item, selected)}
                                                                    >
                                                                        {replaceGaroonName(item, width)}
                                                                    </Grid>
                                                                )
                                                            })}
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>

                        <Grid xs={12} sx={{ mt: 2 }}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <ButtonCustom
                                    child={'戻る'}
                                    onClick={() => {
                                        navigate('/adjustment/init')
                                    }}
                                />
                                <ButtonCustom
                                    child={'候補日表示'}
                                    isSubmit
                                    endIcon={<ArrowForwardIosIcon sx={{ fontSize: '14px !important' }} />}
                                    onClick={handleSubmit(handleFormSubmit)}
                                    onMouseDown={() => {
                                        if (
                                            getValues('subject').length === 0 ||
                                            getValues('event_menu') === '---' ||
                                            getValues('subject').length > 50
                                        ) {
                                            showSnackBar(
                                                true,
                                                '入力エラーがあります。修正して再度実行ボタンをクリックしてください。',
                                                'error',
                                            )
                                        }
                                    }}
                                    disabled={loadingSchedule}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Container>
    )
}

const styles = {
    paper: {
        p: { xs: '20px', sm: '30px', md: 5 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: ' 0px 4px 120px rgba(0, 0, 0, 0.25)',
        color: '#000000',
        marginTop: '28px',
        borderRadius: '20px',
        border: '1px solid transparent',
    },
    targetPeriod: {
        borderRadius: '15px',
        border: '1px solid #666',
        height: '50px',
        width: '100%',
        display: 'flex',
        direction: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        position: 'relative',
        '&:focus': {
            borderColor: '#666666',
        },
        '&:hover': {
            borderColor: '#000000',
        },
    },
    boxLeft: {
        border: '1px solid #666',
        height: {
            xs: '248px',
            sm: '400px',
        },
        borderRadius: '15px',
        overflow: 'hidden',
    },
    boxRight: {
        border: '1px solid #666',
        marginTop: '20px',
        borderRadius: '15px',
        overflow: 'hidden',
    },
    arrow: (theme: any) => ({
        width: '64px',
        height: '64px',
    }),
    userItem: {
        ...inputField,
        ':first-of-type': {
            pt: '10px',
        },
        ':last-child': {
            pb: '10px',
        },
        p: '4px 15px',
        cursor: 'pointer',
    },
    dateField: {
        width: '50%',
        fieldset: {
            border: 'none',
        },
        input: {
            ...inputField,
            textAlign: 'center',
        },
        '.MuiInputBase-root': {
            p: '16.5px 0',
            input: {
                pl: 0,
            },
        },
    },
    label: {
        ...labelOfInputAlign,
        ...labelOfInput,
    },
    error: { marginLeft: '7px !important', position: 'absolute' },
    scheduleTagSelect: {
        position: 'relative',
        '& .MuiSelect-select': { 
            display: 'flex',
            alignItems: 'center',
        },
    },
    scheduleTagMenu: {
        '& .MuiListItemText-primary': {
            fontSize: '16px !important',
        }
    },
    scheduleTagMenuItem: {
        padding: '4px 16px',
    },
    squareBox: {
        width: '14px',
        height: '14px',
        marginRight: '2px',
        marginTop: '1px',
    },
    formRadioLabel: {
        '& .MuiFormControlLabel-label': {
            marginBottom: '3px'
        }
    }
}

export default AdjustmentSingleExternalStep1
