import { useEffect, useState } from 'react'
import ScheduleSingleExternalConfirmFixed from './singleExternalFixed'
import ScheduleSingleExternalConfirmFluid from './singleExternalFluid'
import { useNavigate, useParams } from 'react-router-dom'
import externalUserApi from 'src/api/externalUserApi'

const ScheduleSingleExternalConfirm = () => {
    const navigate = useNavigate()
    const { hash } = useParams()
    const [isFluid, setIsFluid] = useState(false)

    useEffect(() => {
        if (hash) {
            validateHash(hash)
        }
    }, [hash])

    const validateHash = async (hash: string) => {
        externalUserApi
            .validateHashSingleExternal(hash)
            .then((res: any) => {
                const schedule = res.data.schedule
                if(schedule.period_type === 2){
                    setIsFluid(true)
                }
            }).catch((err: any) => {
                setIsFluid(false)
            })
    }

    return (
        <>
            {isFluid ? <ScheduleSingleExternalConfirmFluid /> : <ScheduleSingleExternalConfirmFixed />}
        </>
    )
}

export default ScheduleSingleExternalConfirm

