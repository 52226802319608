import { useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { Container, FormControlLabel, FormHelperText, Stack, Typography, Chip, Select, ListItemText, MenuItem, useTheme } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import scheduleApi from 'src/api/scheduleApi'
import { at, debounce } from 'lodash'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded'
import PeopleAltSharpIcon from '@mui/icons-material/PeopleAltSharp'
import ComponentStepper from '../../adjustment/components/Stepper'
import { TextInput } from 'src/components/TextInput'
import ButtonCustom from 'src/components/Common/ButtonCustom'
import ModalConfirm from 'src/components/Common/ModalConfirm'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { replaceGaroonName } from '../../../helpers/helpers'
import useWindowDimensions from 'src/hook/useWindowDimensions'
import { selectCurrentUser } from 'src/pages/Auth/authSlice'
import adjustmentApi from 'src/api/adjustmentApi'
import { ArrowLeftIcon } from 'src/components/Icons'
import SelectCustom from 'src/components/SelectCustom'
import {
    adjustmentActions,
    selectFacilities,
    selectFacilitiesInUsed,
    selectFacilityGroups,
    selectFilterFacilities,
    selectInfoSchedule,
    selectLoadingFacilitiesInUsed,
} from '../adjustmentSlice'
import { getSelectorsByUserAgent } from 'react-device-detect'
import { inputField } from 'src/theme'
import dayjs from 'dayjs'
import { CONSTANTS } from 'src/config/constants'
import { topActions } from 'src/pages/Top/topSlice'

const FacilitySelection = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { sid } = useParams()
    const { height, width } = useWindowDimensions();
    const { showSnackBar } = useSnackBar()
    const [scheduleData, setScheduleData] = useState<any>(null)
    const [garoonMemo, setGaroonMemo] = useState('')
    const currentUser = useAppSelector(selectCurrentUser)
    const [openModalConfirm, setOpenModalConfirm] = useState(false)
    const [typeModal, setTypeModal] = useState('confirm')
    const [isLoading, setIsLoading] = useState(false)
    const [titleModalConfirm, setTitleModalConfirm] = useState('')
    const [messageModalConfirm, setMessageModalConfirm] = useState('')
    const [checkEventMenu, setCheckEventMenu] = useState(true)
    const [scheduleTags, setScheduleTags] = useState<any>([])
    const groupFacilityList = useAppSelector(selectFacilityGroups)
    const facilities = useAppSelector(selectFacilities)
    const filterFacility = useAppSelector(selectFilterFacilities)
    const [leftFacilitySelected, setLeftFacilitySelected] = useState<any[]>([])
    const [rightFacilitySelected, setRightFacilitySelected] = useState<any[]>([])
    const [errorFacilityBoxLeft, setErrorFacilityBoxLeft] = useState<boolean>(false)
    const [rightDataFacilitySelected, setRightDataFacilitySelected] = useState<any>([])
    const [leftDataFacilitySelected, setLeftDataFacilitySelected] = useState<any>([])
    const facilitiesInUsed = useAppSelector(selectFacilitiesInUsed);
    const loadingFacilitiesInUsed = useAppSelector(selectLoadingFacilitiesInUsed);
    const userAgent = window.navigator.userAgent
    const {isMobile}  = getSelectorsByUserAgent(userAgent)
    const theme = useTheme()
    const infoSchedule = useAppSelector(selectInfoSchedule)
    const location = useLocation();
    const adjustmentData = location.state?.adjustmentData?.data; 

    const handleOpenModal = (type: string, title: string, message: string) => {
        setTypeModal(type)
        setTitleModalConfirm(title)
        setMessageModalConfirm(message)
        setOpenModalConfirm(true)
    }

    const getListScheduleTags = () => {
        adjustmentApi.getListScheduleTags().then((res: any) => {
            if (res.success) {
                setScheduleTags(res.data);
            }
            return;
        })
        .catch((err) => {
            return;
        })
    }

    useEffect(() => {
        getScheduleData()
        getListScheduleTags()
        dispatch(adjustmentActions.fetchFacilityGroups())
    }, [])

    useEffect(() => {
        if (adjustmentData) {
            dispatch(adjustmentActions.fetchFacilitiesInUsed({
                start_time: adjustmentData[0].start_time,
                end_time: dayjs(adjustmentData[0].start_time).add(adjustmentData[0].duration, 'minute').toISOString()
            }))
        }
    }, [adjustmentData])

    useEffect(() => {
        dispatch(adjustmentActions.fetchFacilities(filterFacility))
    }, [filterFacility])

    const getScheduleData = () => {
        scheduleApi
            .getById({
                id: Number(sid),
            })
            .then((res) => {
                setScheduleData(res.data)
                if (!res.data.event_menu || res.data.event_menu.trim().length === 0) {
                    setCheckEventMenu(false)
                }
            })
            .catch((err) => {
                console.log('Facility Selection -> scheduleApi.getById failed: ', err)
            })
    }

    const onChangeGaroonMemo = debounce((e) => {
        setGaroonMemo(e.target.value)
    }, 500)

    const handleSubmit = () => {
        if (!infoSchedule.facilities || infoSchedule.facilities.length === 0) {
            handleOpenModal('confirm', '確認', '施設が指定されていません。このまま続けてもよろしいですか？')
        } else {
            submit();
        }
    }

    const submit = () => {
        setIsLoading(true);
        //Handle finalize schedule
        let facilityArr = [];
        if (infoSchedule?.facilities?.length > 0) {
            facilityArr = infoSchedule.facilities.map((obj: any) => {
                return {
                    code: obj.code,
                    name: obj.name,
                }
            })
        }

        if (!adjustmentData) {
            return showSnackBar(true, `予定が無効です`, 'error');
        }

        const emailSubject = `【GLOBAL PRODUCE】${scheduleData?.subject || ''}／日時確定のお知らせ`;
        const emailBody =  `{name} 様<br><br>いつもお世話になっております。<br>` 
            + `グローバルプロデュースの${replaceGaroonName(scheduleData?.user, width, true)}です。<br><br>`
            + `予定が以下の日時で確定いたしましたので<br>ご確認のほどよろしくお願い致します。<br><br>`
            + `---<br>タイトル：${
                scheduleData?.subject || ''
            }<br>日時：${adjustmentData[0]?.duration !== 1439 
                ? dayjs(adjustmentData[0]?.start_time).format(`YYYY/M/D(${
                    CONSTANTS.DAY_OF_WEEK[dayjs(adjustmentData[0]?.start_time).day()]
                }) H:mm`,) || ''
                : dayjs(adjustmentData[0]?.start_time).format(`YYYY/M/D(${
                    CONSTANTS.DAY_OF_WEEK[dayjs(adjustmentData[0]?.start_time).day()]
                })`)
            }〜（${
                adjustmentData[0]?.duration
                    ? adjustmentData[0]?.duration === 1439
                        ? '終日'
                        : adjustmentData[0]?.duration + '分間'
                    : ''
            }）<br><br>`
            + `${garoonMemo?.length > 0 ? garoonMemo : `<strong>オンラインMTGの場合はこちらにURLを記載ください</strong>`}<br><br>`
            + `当日お会いできるのを<br>楽しみにしております。<br><br>`
            + `----------------------------------------------------<br>`
            + `株式会社グローバルプロデュース<br>`
            + `${replaceGaroonName(scheduleData?.user, width, true)}<br>`
            + `〒150-0001    東京都渋谷区神宮前5-52-2<br>`
            + `青山オーバルビル13F</br>`
            + `OFFICE／03-5738-2117 （FAX2118）<br>`
            + `https://www.global-produce.jp/`;

        const dataInput = {
            start_time: adjustmentData[0].start_time,
            end_time: dayjs(adjustmentData[0].start_time).add(adjustmentData[0].duration, 'minute').toISOString(),
            margin_time: adjustmentData[0].margin_time,
            note: garoonMemo,
            facilities: facilityArr,
            email_subject: emailSubject,
            email_body: emailBody,
        };
        
        scheduleApi
            .finalize(sid, dataInput)
            .then((res: any) => {
                if (res?.success) {
                    dispatch(topActions.setScheduleFinalized(scheduleData));
                    showSnackBar(true, `予定「${scheduleData.subject}」の日時が確定しました`, 'success');
                    navigate('/schedule-list', {state: { selectTab:'FINALIZED'} });
                } else {
                    console.log(res.message);
                }
            })
            .catch((err) => console.log(err));

        setIsLoading(false);
    }

    const handleChangeRightDataFacility = (event: any) => {
        setLeftDataFacilitySelected([])
        setLeftFacilitySelected([])
        let options = event.target.options;
        let value = [];
        let facility = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                facility.push(JSON.parse(options[i].getAttribute('data-facility')))
            }
        }
        setRightFacilitySelected(facility)
        setRightDataFacilitySelected(value)
    }

    const handleChangeLeftDataFacility = (event: any) => {
        setRightDataFacilitySelected([])
        setRightFacilitySelected([])
        let options = event.target.options;
        let value = [];
        let facility = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
                facility.push(JSON.parse(options[i].getAttribute('data-facility')))
            }
        }
        setLeftDataFacilitySelected(value)
        setLeftFacilitySelected(facility)
    }

    const handleClickFacilityItem = (frame: string, facility: any, selected: boolean) => {
        if (frame === 'left') {
            if (selected) {
                setLeftFacilitySelected((data) => data.filter((e) => e.garoon_id !== facility.garoon_id))
            } else {
                setLeftFacilitySelected((data) => [...data, facility])
            }
        } else if (selected) {
            setRightFacilitySelected((data) => data.filter((e) => e.garoon_id !== facility.garoon_id))
        } else {
            if (facilitiesInUsed.length > 0 && facilitiesInUsed.indexOf(facility.garoon_id) > -1) {
                return;
            }
            setRightFacilitySelected((data) => [...data, facility])
        }
    }

    const handleFacilityArrow = (to: string) => {
        if (to === 'left') {
            if (rightFacilitySelected.length === 0) {
                return
            }

            // add facilities selected right to left
            dispatch(
                adjustmentActions.setInfoSchedule({
                    ...infoSchedule,
                    facilities: [...infoSchedule.facilities, ...rightFacilitySelected],
                }),
            )
            setErrorFacilityBoxLeft(false)
            setRightFacilitySelected([])

            setRightDataFacilitySelected([])
            setLeftDataFacilitySelected([])
        } else {
            if (leftFacilitySelected.length === 0) {
                return
            }
            // if (leftFacilitySelected.length === infoSchedule.facilities.length) {
            //     setErrorFacilityBoxLeft(true)
            // }
            // remove users selected left
            dispatch(
                adjustmentActions.setInfoSchedule({
                    ...infoSchedule,
                    facilities: [
                        ...infoSchedule.facilities.filter((e: any) => !leftFacilitySelected.some((i) => i.garoon_id === e.garoon_id)),
                    ],
                }),
            )
            setLeftFacilitySelected([])

            setRightDataFacilitySelected([])
            setLeftDataFacilitySelected([])
        }
    }

    const onChangeTextSearchFacility = (text: string) => {
        dispatch(
            adjustmentActions.setFilterFacility({
                ...filterFacility,
                text,
            }),
        )
    }

    if (scheduleData == null) {
        return
    }

    return (
        <Container sx={{ p: '0 !important'}}>
            <Grid container spacing={4}>
                <Grid item xs={12} sx={{ mt: '14px', mb: '28px' }}>
                    <ComponentStepper activeStep={2} />
                </Grid>
            </Grid>
            <Grid container sx={styles.formBackground}>
                <Grid item xs={12}>
                    <Box sx={styles.adjustmentTitle}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            gap={1}
                        >
                            <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}}
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >
                                    <InsertDriveFileOutlinedIcon sx={styles.boxAddInviteeIcon}/>
                                    <Typography sx={styles.label}>予定タイトル</Typography>
                                </Stack>
                                <Stack
                                    direction={'row'}
                                    spacing={{ xs: 1, xsm: 2}}
                                    sx={{
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 130px)',
                                        },
                                    }}
                                >
                                    {
                                        (() => {
                                            if (checkEventMenu) {
                                                let tag = scheduleTags.find((element: any) => element?.name == scheduleData?.event_menu)
                                                return (
                                                    <Box 
                                                        sx={[
                                                            styles.boxScheduleEventMenu, 
                                                            {
                                                                height: '21px',
                                                                backgroundColor: tag && tag?.color ? tag?.color : '#9F7AEA',
                                                            }
                                                        ]}
                                                    >
                                                        <Typography sx={styles.boxScheduleEventMenuText}>
                                                            {scheduleData.event_menu}
                                                        </Typography>
                                                    </Box>
                                                )
                                            } else {
                                                return ''
                                            }
                                        })()
                                    }
                                    <Typography
                                        sx={{ 
                                            lineHeight: 1.4, 
                                            fontSize: '15px',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        {scheduleData.subject}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} spacing={{ xs: 1, sm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}}
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >
                                    <EventNoteRoundedIcon sx={styles.boxAddInviteeIcon}/>
                                    <Typography sx={styles.label}>予定日時</Typography>
                                </Stack>
                                <Stack 
                                    direction={'row'}
                                    sx={{
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 130px)',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            lineHeight: 1.4,
                                            fontSize: '15px',
                                            whiteSpace: 'normal'
                                        }}
                                    >
                                        {dayjs(adjustmentData[0].start_time).format(
                                            `YYYY/M/D(${
                                                CONSTANTS.DAY_OF_WEEK[dayjs(adjustmentData[0].start_time).day()]
                                            }) H:mm`,
                                        )}
                                        {`〜 (${
                                            adjustmentData[0]?.duration
                                                ? adjustmentData[0]?.duration === 1439
                                                    ? '終日'
                                                    : adjustmentData[0]?.duration + '分間'
                                                : ''
                                        }）`}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} spacing={{ xs: 1, sm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}}
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >
                                    <PeopleAltSharpIcon sx={styles.boxAddInviteeIcon}/>
                                    <Typography sx={styles.label}>参加者</Typography>
                                </Stack>
                                <Stack 
                                    direction={'row'}
                                    sx={{
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 130px)',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            lineHeight: 1.4, 
                                            fontSize: '15px',
                                            whiteSpace: 'normal'
                                        }}
                                    >
                                        {scheduleData && scheduleData.participant.map((e: any) => {
                                            return replaceGaroonName(e.user, width);
                                        }).join('、')}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Box>

                        <Grid item xs={12}>
                            <Stack spacing={1} sx={{ mt: '30px', position: 'relative' }}>
                                <Typography sx={styles.label}>オンラインMTG URL<Typography component="span" sx={{ fontSize: '13px' }}>　※任意</Typography></Typography>
                                <TextInput
                                    onChange={onChangeGaroonMemo}
                                    placeholder=" 例）https://us06web.zoom.us/j/xxxxx"
                                />

                            </Stack>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: '20px', position: 'relative',  mb: 5 }}>
                            <Stack spacing={1} sx={{ mt: '30px', position: 'relative', mb: 2 }}>
                                <Typography sx={styles.label}>施設を選択<Typography component="span" sx={{ fontSize: '13px' }}>　※任意</Typography></Typography>
                            </Stack>
                            <Stack 
                                sx={{width: '100%'}} 
                                direction={{
                                    xs: 'column-reverse',
                                    sm: 'row',
                                }} 
                                spacing={0}
                            >
                                <Grid item xs={12} sm={5.5}>
                                    {
                                        !isMobile ? (
                                            <Select
                                                fullWidth
                                                multiple
                                                native
                                                autoFocus={true}
                                                value={leftDataFacilitySelected}
                                                onChange={handleChangeLeftDataFacility}
                                                sx={{
                                                    marginBottom: {
                                                        xs: '0px',
                                                        sm: '22px',
                                                    },
                                                    height: {
                                                        xs: '248px',
                                                        sm: '400px',
                                                    },
                                                    '& .MuiNativeSelect-select[multiple]': {
                                                        overflow: 'auto',
                                                        padding: '0px !important',
                                                        height: '100%',
                                                        borderRadius: '15px',
                                                    },
                                                    ' & option': {
                                                        ':checked, :focus, :active': {
                                                            background: 'linear-gradient(#ffff00,#ffff00) !important',
                                                            boxShadow: '0 0 10px 100px #ffff00 inset !important',
                                                            backgroundColor: '#ffff00 !important',
                                                            color: '#000000 !important',
                                                            WebkitTextFillColor: '#000000 !important',
                                                        },
                                                        ':first-of-type': {
                                                            pt: '10px',
                                                        },
                                                        ':last-of-type': {
                                                            pb: '10px',
                                                        },
                                                        padding: '4px 15px',
                                                        fontSize: '16px',
                                                    },
                                                    '&.MuiInputBase-root': {
                                                        fieldset: {
                                                            border: errorFacilityBoxLeft ? '1px solid red' : '1px solid #666',
                                                            borderRadius: '15px',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: '1px solid #666',
                                                            borderRadius: '15px',
                                                        },
                                                    },
                                                }}
                                            >
                                                {infoSchedule?.facilities && infoSchedule.facilities.map((item: any) => {
                                                        return (
                                                            <option 
                                                                key={item.id} 
                                                                value={item.garoon_id}
                                                                data-facility={JSON.stringify(item)}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        ) : (
                                            <Box sx={[styles.boxLeft, errorFacilityBoxLeft && { border: '1px solid red' }]}>
                                                <Box 
                                                    sx={{ 
                                                        overflow: 'auto', 
                                                        height: {
                                                            xs: '248px',
                                                            sm: '400px',
                                                        }
                                                    }}
                                                >
                                                    {infoSchedule?.facilities && infoSchedule.facilities.map((item: any) => {
                                                        const selected = leftFacilitySelected.some((i) => i.garoon_id === item.garoon_id)
                                                        return (
                                                            <Grid
                                                                key={item.garoon_id}
                                                                sx={[
                                                                    styles.userItem,
                                                                    selected && {
                                                                        backgroundColor: theme.palette.secondary.main,
                                                                    },
                                                                ]}
                                                                tabIndex={0}
                                                                onClick={() => handleClickFacilityItem('left', item, selected)}
                                                            >
                                                                {item.name}
                                                            </Grid>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                        )
                                    }
                                    <FormHelperText error sx={styles.error}>
                                        {errorFacilityBoxLeft && '少なくとも 1 人の参加者を追加してください'}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: {
                                                xs: 'row',
                                                sm: 'column',
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                transform: {
                                                    xs: 'rotate(270deg)',
                                                    sm: 'rotate(0deg)',
                                                },
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleFacilityArrow('left')}
                                        >
                                            <ArrowLeftIcon />
                                        </Box>
                                        <Box
                                            sx={{
                                                transform: {
                                                    xs: 'rotate(90deg)',
                                                    sm: 'rotate(180deg)',
                                                },
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleFacilityArrow('right')}
                                        >
                                            <ArrowLeftIcon />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={5.5}>
                                    <Stack
                                        sx={{
                                            height: '100%',
                                            justifyContent: 'space-between',
                                            flexDirection: 'column',
                                        }}
                                        spacing={2}
                                    >
                                        <SelectCustom
                                            value={filterFacility.groupId}
                                            onChange={(event: any) => {
                                                dispatch(
                                                    adjustmentActions.setFilterFacility({
                                                        ...filterFacility,
                                                        groupId: event.target.value,
                                                    }),
                                                )
                                                setRightFacilitySelected([])
                                            }}
                                        >
                                            {groupFacilityList && groupFacilityList.map((groupFacility: any, index: number) => (
                                                <MenuItem key={`${groupFacility.id}`} value={groupFacility.garoon_group_id}>
                                                    <ListItemText primary={groupFacility.name} />
                                                </MenuItem>
                                            ))}
                                        </SelectCustom>
                                        <TextInput
                                            value={filterFacility.text}
                                            autoComplete="off"
                                            onChange={(event: any) => {
                                                onChangeTextSearchFacility(event.target.value)
                                                setRightFacilitySelected([])
                                            }}
                                            placeholder="施設を探す"
                                        />
                                        {
                                            !isMobile ? (
                                                <Box>
                                                    <Select
                                                        fullWidth
                                                        multiple
                                                        native
                                                        value={rightDataFacilitySelected}
                                                        onChange={handleChangeRightDataFacility}
                                                        sx={{
                                                            height: '248px',
                                                            '& .MuiNativeSelect-select[multiple]': {
                                                                overflow: 'auto',
                                                                padding: '0px',
                                                                height: '100%',
                                                                borderRadius: '15px',
                                                            },
                                                            ' & option': {
                                                                ':checked, :focus, :active': {
                                                                    background: 'linear-gradient(#ffff00,#ffff00)',
                                                                    boxShadow: '0 0 10px 100px #ffff00 inset !important',
                                                                    backgroundColor: '#ffff00 !important',
                                                                    color: '#000000 !important',
                                                                    WebkitTextFillColor: '#000000 !important',
                                                                },
                                                                ':first-of-type': {
                                                                    pt: '10px',
                                                                },
                                                                ':last-of-type': {
                                                                    pb: '10px',
                                                                },
                                                                padding: '4px 15px',
                                                                fontSize: '16px',
                                                            },
                                                            '&.MuiInputBase-root': {
                                                                fieldset: {
                                                                    border: '1px solid #666',
                                                                    borderRadius: '15px',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    border: '1px solid #666',
                                                                    borderRadius: '15px',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {!loadingFacilitiesInUsed ?
                                                            facilities && facilities.filter((facilities: any) =>
                                                                !infoSchedule?.facilities?.some(
                                                                    (e: any) => e.garoon_id === facilities.garoon_id,
                                                                ),).map((item: any) => {
                                                                    return (
                                                                        <option 
                                                                            disabled={facilitiesInUsed.length > 0 && facilitiesInUsed.indexOf(item.garoon_id) > -1}
                                                                            key={item.id} 
                                                                            value={item.garoon_id}
                                                                            data-facility={JSON.stringify(item)}
                                                                            style={facilitiesInUsed.length > 0 && facilitiesInUsed.indexOf(item.garoon_id) == -1 ? {paddingLeft: '35px'} : {}}
                                                                            className='warning'
                                                                        >
                                                                            {facilitiesInUsed.length > 0 ? facilitiesInUsed.indexOf(item.garoon_id) > -1 ? '⚠ ' : "" : ''}{item.name}
                                                                        </option>
                                                                    )
                                                                })
                                                            :
                                                            <option style={{pointerEvents: 'none'}}>読み込み中..</option>
                                                        }
                                                    </Select>
                                                    <Box sx={{display: 'flex',mt: 0.5, pl: 2, fontSize: '12px'}}>
                                                        ⚠予定日時に空いていない施設
                                                    </Box>

                                                </Box>
                                            ) : (
                                                <Box>
                                                    <Box sx={styles.boxRight}>
                                                        <Box sx={{ overflow: 'auto', height: '248px' }}>
                                                            {!loadingFacilitiesInUsed ?
                                                                facilities && facilities
                                                                    .filter(
                                                                        (facilities: any) =>
                                                                            !infoSchedule?.facilities.some(
                                                                                (e: any) => e.garoon_id === facilities.garoon_id,
                                                                            ),
                                                                    )
                                                                    .map((item: any) => {
                                                                        const selected = rightFacilitySelected.some((i) => i.garoon_id === item.garoon_id)
                                                                        return (
                                                                            <Grid
                                                                                // disabled={facilitiesInUsed && facilitiesInUsed.indexOf(item.garoon_id) > -1}
                                                                                key={item.garoon_id}
                                                                                sx={[
                                                                                    styles.userItem,
                                                                                    selected && {
                                                                                        backgroundColor: theme.palette.secondary.main,
                                                                                    },
                                                                                    facilitiesInUsed.length > 0 && facilitiesInUsed.indexOf(item.garoon_id) == -1 ? {paddingLeft: '35px'} : {}
                                                                                ]}
                                                                                tabIndex={0}
                                                                                onClick={() => handleClickFacilityItem('right', item, selected)}
                                                                            >
                                                                                {facilitiesInUsed.length > 0 ? facilitiesInUsed.indexOf(item.garoon_id) > -1 ? '⚠ ' : "" : ''}{item.name}
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                :
                                                                <Grid
                                                                    sx={[
                                                                        styles.userItem,
                                                                        {pointerEvents: 'none'}
                                                                    ]}
                                                                    tabIndex={0}
                                                                >
                                                                    読み込み中..
                                                                </Grid>
                                                            }
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{display: 'flex',mt: 0.5, pl: 2, fontSize: '12px'}}>
                                                        ⚠予定日時に空いていない施設
                                                    </Box>
                                                </Box>
                                                
                                            )
                                        }
                                    </Stack>
                                </Grid>
                            </Stack>
                        </Grid>

                        <Box sx={styles.footer}>
                            <ButtonCustom child={'戻る'} onClick={() => navigate(`/adjustment/step2/${sid}`, { state: { dataLocation: {is_creating: true} } })} />
                            <Box sx={{ flex: '1 1 auto' }} />
                            <ButtonCustom child={'確定'} isSubmit onClick={handleSubmit} disabled={isLoading} />
                        </Box>
                        <ModalConfirm
                            type={typeModal}
                            openModalConfirm={openModalConfirm}
                            isLoading={isLoading}
                            closeModalConfirm={() => setOpenModalConfirm(false)}
                            onConfirm={() => {
                                submit()
                            }}
                            title={titleModalConfirm}
                            message={messageModalConfirm}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default FacilitySelection

const styles = {
    formBackground: {
        width: '100%',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
    },
    label: {
        lineHeight: 1.4,
        fontSize: '15px',
        fontWeight: '500',
        height: '21px',
        margin: 'auto 0',
    },
    adjustmentTitle: {
        p: { xs: '20px', sm: '30px', md: 5 },
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0px 4px 120px rgba(0, 0, 0, 0.25)',
        borderRadius: '20px',
    },
    boxScheduleEventMenu: {
        color: '#FFF',
        borderRadius: '10px',
        p: '0px 10px',
        display: 'flex',
        alignItems: 'center',
    },
    boxScheduleEventMenuText: {
        fontSize: '12px',
        fontWeight: '500',
    },
    boxAddInvitee: { display: 'flex', ml: '7px', cursor: 'pointer', gap: '8px' },
    boxAddInviteeIcon: {
        fontSize: '21px',
    },
    footer: { display: 'flex', flexDirection: 'row' },
    userItem: {
        ...inputField,
        ':first-of-type': {
            pt: '10px',
        },
        ':last-child': {
            pb: '10px',
        },
        p: '4px 15px',
        cursor: 'pointer',
    },
    boxLeft: {
        border: '1px solid #666',
        height: {
            xs: '248px',
            sm: '400px',
        },
        borderRadius: '15px',
        overflow: 'hidden',
    },
    boxRight: {
        border: '1px solid #666',
        marginTop: '20px',
        borderRadius: '15px',
        overflow: 'hidden',
    },
    error: { marginLeft: '7px !important', position: 'absolute' },
}
