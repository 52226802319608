import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles';
import {
    Checkbox,
    FormHelperText,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tooltip,
    Chip,
    Link,
    tooltipClasses,
    Container,
    FormControlLabel,
    Paper,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useNavigate, useParams } from 'react-router-dom'
import scheduleApi from 'src/api/scheduleApi'
import adjustmentApi from 'src/api/adjustmentApi'
import dayjs from 'dayjs'
import { useSnackBar } from 'src/components/Common/SnackBarContext'
import { limitCharacter } from '../../helpers/string'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextInput } from 'src/components/TextInput'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded'
import PeopleAltSharpIcon from '@mui/icons-material/PeopleAltSharp'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import LightFieldByPoint from 'src/components/Common/LightFieldPoint'
import { CONSTANTS } from 'src/config/constants'
import { tableRowAlign, tableBodyText, tableHeaderText, labelOfInput, labelOfInputAlign } from 'src/theme'
import ButtonCustom from 'src/components/Common/ButtonCustom'
import { formatTargetDatePeriod2 } from '../../helpers/formatDate'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useAppDispatch } from 'src/app/hooks'
import moment from 'moment'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import ModalConfirm from 'src/components/Common/ModalConfirm'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import { replaceGaroonName } from '../../helpers/helpers'
import useWindowDimensions from 'src/hook/useWindowDimensions'
import { ExternalLinkIcon, HelpCenterIcon } from 'src/components/Icons'
import { forEach } from 'lodash';
import ComponentStepper from '../adjustment/components/Stepper';
import { adjustmentActions } from '../adjustment/adjustmentSlice';

function CommentIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 512 512">
            <path d="M168.2 384.9c-15-5.4-31.7-3.1-44.6 6.4c-8.2 6-22.3 14.8-39.4 22.7c5.6-14.7 9.9-31.3 11.3-49.4c1-12.9-3.3-25.7-11.8-35.5C60.4 302.8 48 272 48 240c0-79.5 83.3-160 208-160s208 80.5 208 160s-83.3 160-208 160c-31.6 0-61.3-5.5-87.8-15.1zM26.3 423.8c-1.6 2.7-3.3 5.4-5.1 8.1l-.3 .5c-1.6 2.3-3.2 4.6-4.8 6.9c-3.5 4.7-7.3 9.3-11.3 13.5c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c5.1 0 10.2-.3 15.3-.8l.7-.1c4.4-.5 8.8-1.1 13.2-1.9c.8-.1 1.6-.3 2.4-.5c17.8-3.5 34.9-9.5 50.1-16.1c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9zM144 272a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm144-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm80 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/>
        </SvgIcon>
    );
}

const schema = yup.object({
    email_subject: yup
        .string()
        .required('必ず入力してください。')
        .max(100, 'タイトルは100文字以内で入力してください。'),
    email_body: yup.string().required('必ず入力してください。').max(5000, '本文は5000文字以内で入力してください。'),
})

const FinalizeStep1 = () => {
    const dispatch = useAppDispatch()
    const { sid } = useParams()
    const { showSnackBar } = useSnackBar()
    const navigate = useNavigate()
    const [idxChecked, setIdxChecked] = useState<number>(-1)
    const [isShowModalSendReminder, setIsShowModalSendReminder] = useState<boolean>(false)
    const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false)
    const [scheduleData, setScheduleData] = useState<any>(null)
    const [notRepondentUsers, setNotRepondentUsers] = useState<any>(null)
    const [adjustmentData, setAdjustmentData] = useState<any>([])
    const maxPoint = useRef<number>(0)
    const [checkEventMenu, setCheckEventMenu] = useState(true)
    const [hasUserNotConfirm, setHasUserNotConfirm] = useState<boolean>(false)
    const [loadedTime, setLoadedTime] = useState<string>('')
    const { height, width } = useWindowDimensions();
    const [isLoading, setIsLoading] = useState(false)
    const [garoonLink, setGaroonLink] = useState<string>('')

    useEffect(() => {
        if (sid) {
            dispatch(adjustmentActions.fetchScheduleById(Number(sid)))
        } 
    }, [sid])

    const nextStep2 = () => {
        navigate(`/finalize/${sid}/step2`,{state: { adjustmentData: adjustmentData[idxChecked] }})
    }

    const [remindEmailSubject, setRemindEmailSubject] = useState<string>(`【GLOBAL PRODUCE】${scheduleData?.subject || ''}／スケジュールご確認のリマインド`)
    const [remindEmailBody, setRemindEmailBody] = useState<string>(`{name} 様<br><br>
        いつもお世話になっております。<br>
        グローバルプロデュースの${replaceGaroonName(scheduleData?.user, width, true)}です。<br><br>
        「${scheduleData?.subject || ''}」について、<br>
        日程調整の回答をご連絡頂いていない方へのリマインドとなります。<br><br>
        お手数ですが、下記リンクより参加可能な日時をお知らせください。<br>
        {url}<br><br>
        回答期限を過ぎているため大変恐縮ですが、<br>
        本日中にはご返信を頂けますと大変幸いです。<br>`)

    const {
        control,
        handleSubmit,
        getValues,
        reset,
        formState: { isSubmitting, errors, isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            email_subject: '',
            email_body: '',
            is_cc_internal_participants: true,
            attachment_file: [],
        },
        values: {
            email_subject: `【GLOBAL PRODUCE】${scheduleData?.subject || ''}／スケジュールご確認のリマインド`,
            email_body: `{name} 様<br><br>`
                + `いつもお世話になっております。<br>`
                + `グローバルプロデュースの${replaceGaroonName(scheduleData?.user, width, true)}です。<br><br>`
                + `「${scheduleData?.subject || ''}」について、<br>`
                + `日程調整の回答をご連絡頂いていない方へのリマインドとなります。<br><br>`
                + `お手数ですが、下記リンクより参加可能な日時をお知らせください。<br>`
                + `{url}<br><br>`
                + `回答期限を過ぎているため大変恐縮ですが、<br>`
                + `<strong>本日中</strong>にはご返信を頂けますと大変幸いです。<br><br>`
                + `----------------------------------------------------<br>`
                + `株式会社グローバルプロデュース<br>`
                + `${replaceGaroonName(scheduleData?.user, width, true)}<br>`
                + `〒150-0001    東京都渋谷区神宮前5-52-2<br>`
                + `青山オーバルビル13F</br>`
                + `OFFICE／03-5738-2117 （FAX2118）<br>`
                + `https://www.global-produce.jp/`
        },
        resolver: yupResolver(schema),
    })

    const [scheduleTags, setScheduleTags] = useState<any>([])
    const getListScheduleTags = () => {
        adjustmentApi.getListScheduleTags().then((res: any) => {
            if (res.success) {
                setScheduleTags(res.data);
            }
            return;
        })
        .catch((err) => {
            return;
        })
    }

    useEffect(() => {
        const params = {
            id: Number(sid),
        }
        scheduleApi
            .getById(params)
            .then((res) => {
                let data = res.data

                if (data == undefined || data == null || data.length == 0 ) {
                    showSnackBar(true, `予定が無効です`, 'error')
                    navigate('/schedule-list')
                }

                if (!res.data.event_menu || res.data.event_menu.trim().length === 0) {
                    setCheckEventMenu(false)
                }

                if (data.status !== 'ADJUSTING' || data.adjustment_end_date < dayjs().add(3, 'hour').toISOString()) {
                    showSnackBar(true, `予定が無効です`, 'error')
                    navigate('/schedule-list')
                }
                setScheduleData(data)
                let notRepodent = data.participant.filter((e: any) =>!e.is_confirmed && e.hash != null);
                setNotRepondentUsers(notRepodent);
                setRemindEmailSubject(`【GLOBAL PRODUCE】予定「${data?.subject || ''}」リマインドのお知らせ`)
                setRemindEmailBody(`{name} 様<br><br>
                いつもお世話になっております。<br>
                グローバルプロデュースの${replaceGaroonName(data?.user, width, true)}です。<br><br>
                「${data?.subject || ''}」について、まだ日程調整の回答をいただけていない方へリマインドのご連絡となります。<br>
                お手数ですが、下記リンクより参加可能な日時をお知らせください。<br>
                {url}<br><br>
                どうぞよろしくお願い致します。`)
            })
            .catch((err) => {
                console.log('Finalzied -> scheduleApi.getById failed: ', err)
                showSnackBar(true, `予定が無効です`, 'error')
                navigate('/schedule-list')
            })

        adjustmentApi
            .getListAdjustmentByScheduleId(sid)
            .then((res: any) => {
                if (res?.success) {
                    setAdjustmentData(res.data.candidate_time)
                    setGaroonLink(res.data.garoon_view_link)
                    setLoadedTime(dayjs().format('YYYY/M/D HH:mm'))
                } else {
                    showSnackBar(true, `予定が無効です`, 'error')
                    navigate('/schedule-list')
                }
            })
            .catch((err) => {
                console.log('Finalzied -> adjustmentApi.getListAdjustmentByScheduleId failed: ', err)
                navigate('/schedule-list')
            })

        getListScheduleTags()
    }, [])

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const fileMimeType = [
        'image/jpeg',
        'image/png',
        'image/jpg',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ]

    const [fileAttachment, setFileAttachment] = useState<any[]>([]);
    const [filesData, setFilesData] = useState<any[]>([]);
    const useRefFileInput = useRef<any>(null);
    const [errorFile, setErrorFile] = useState('');
    const handleFileAttachment = (event: any) => {
        const {files} = event.target;

        const validImageFiles = [];
        let isValidate = false;
        setErrorFile('');
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!fileMimeType.includes(file.type)) {
                // reset file input
                if(useRefFileInput && useRefFileInput.current) {
                    useRefFileInput.current.value = '';
                }
                setErrorFile('ファイル形式が正しくありません');
                isValidate = true;
                return;
            }
            if (file.size > 10485760) {
                // reset file input
                if(useRefFileInput && useRefFileInput.current) {
                    useRefFileInput.current.value = '';
                }
                setErrorFile('10MB以下のファイルを添付してください。');
                isValidate = true;
                return;
            }

            // if (filesData && filesData.length > 0 && filesData.some((e: any) => e.name === file.name)) {
            //     // reset file input
            //     if(useRefFileInput && useRefFileInput.current) {
            //         useRefFileInput.current.value = '';
            //     }
            //     showSnackBar(true, 'ファイルが重複しています', 'error');
            //     isValidate = true;
            //     return;
            // }

            validImageFiles.push(file);
        }

        if (!isValidate && validImageFiles.length > 0) {
            setFileAttachment(validImageFiles);
        }
    }

    useEffect(() => {
        // reset file input
        if(useRefFileInput && useRefFileInput.current) {
            useRefFileInput.current.value = '';
        }

        let fileReaders: any[] = [];
        let isCancel = false;
        if (fileAttachment.length > 0) {
            forEach(fileAttachment, (file: any) => {
                let fileReader = new FileReader();
                fileReaders.push(fileReader);
                fileReader.onload = (e: any) => {
                    const { result } = e.target;
                    if (result && !isCancel) {
                        setFilesData((prev: any) => {
                            return [
                                ...prev,
                                {
                                    name: file.name,
                                    size: file.size,
                                    type: file.type,
                                    data: result,
                                },
                            ];
                        });    
                    }
                };
                fileReader.readAsDataURL(file);
            })
        }

        return () => {
            isCancel = true;
            fileReaders.forEach(fileReader => {
                if (fileReader.readyState === 1) {
                    fileReader.abort()
                }
            })
        }
    }, [fileAttachment]);

    const handleDeleteFile = (file: any, index: number) => () => {
        setFilesData((prev: any) => {
            const newFilesData = [...prev];
            newFilesData.splice(index, 1);
            return newFilesData;
        });
    };

    const handleFormSubmitRemindMail = (data: any) => {
        setIsLoading(true)
        const params = {
            email_subject: data.email_subject,
            email_body: data.email_body,
            attachment_file: filesData,
        }
        setIsShowModalSendReminder(false)
        setIsLoading(false)
        sendRemindMail(sid, params)
    }

    const componentAttachmentFile = () => {
        return (
            <>
                <Stack 
                    direction={
                        {
                            xs: 'column',
                            sm: 'row',
                        }
                    } 
                    spacing={1}
                >
                    <Controller
                        name="attachment_file"
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Typography
                                component="label"
                                sx={{
                                    color: '#1976d2',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    fontSize: '15px',
                                    minWidth: '140px',
                                    padding: '4px 0 4px 0px',
                                }}
                            >
                                <VisuallyHiddenInput
                                    hidden
                                    multiple
                                    ref={useRefFileInput}
                                    onChange={(event) => handleFileAttachment(event)}
                                    name='attachment_file'
                                    accept="
                                        image/jpeg,image/jpg,image/png
                                        ,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document
                                        ,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
                                        ,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation
                                    "
                                    type="file"
                                    value={''}
                                />
                                ファイルを添付する
                            </Typography>
                        )}
                    />
                    {
                        (() => {
                            let attachmentFiles = filesData;
                            if (attachmentFiles && attachmentFiles.length > 0) {
                                return (
                                    <Stack direction={'row'} spacing={1} useFlexGap sx={{flexWrap: 'wrap'}}>
                                        {
                                            attachmentFiles.map((file: any, index: number) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        label={file.name}
                                                        onDelete={handleDeleteFile(file, index)}
                                                        sx={{ fontSize: '15px' }}
                                                    />
                                                )
                                            })
                                        }
                                    </Stack>
                                )
                            }
                        })()
                    }
                </Stack>
                <FormHelperText
                    error={errorFile.length > 0}
                >
                    {errorFile}
                </FormHelperText>
            </>
        )
    }

    const sendRemindMail = (sid: any, params: any) => {
        scheduleApi
            .remindEmail(sid, params)
            .then((res: any) => {
                if (res?.success) {
                    showSnackBar(true, `リマインダーメールの送信成功`, 'success')
                    reset()
                    setFileAttachment([])
                    setFilesData([])
                    setErrorFile('')
                } else {
                    console.log(res.message)
                }
            })
            .catch((err) => console.log(err))
    }

    if (scheduleData === null || adjustmentData.length === 0) {
        return
    }

    return (
        <Container sx={{ p: '0 !important'}}>
            <Box>
                <Grid container spacing={4}>
                    <Grid xs={12} sx={{ mt: '12px' }}>
                        <ComponentStepper activeStep={0} listSteps={['1.日時確定', '2.施設選択', '3.メール送信']} />
                    </Grid>
                </Grid>
            </Box>
            <Paper elevation={3} sx={styles.paper}>
            <Box component="form" noValidate>
                <Grid container spacing={0} sx={styles.container}>
                    <Box sx={styles.adjustmentTitle}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            gap={1}
                        >
                            <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}} 
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >
                                    <InsertDriveFileOutlinedIcon sx={styles.boxAddInviteeIcon}/>
                                    <Typography sx={styles.label}>予定タイトル</Typography>
                                </Stack>
                                <Stack
                                    direction={'row'}
                                    spacing={{ xs: 1, xsm: 2}}
                                    sx={{
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 130px)',
                                        },
                                    }}
                                >
                                    {
                                        (() => {
                                            if (checkEventMenu) {
                                                let tag = scheduleTags.find((element: any) => element?.name == scheduleData?.event_menu)
                                                return (
                                                    <Box 
                                                        sx={[
                                                            styles.boxScheduleEventMenu, 
                                                            {
                                                                height: '21px',
                                                                backgroundColor: tag && tag?.color ? tag?.color : '#9F7AEA',
                                                            }
                                                        ]}
                                                    >
                                                        <Typography sx={styles.boxScheduleEventMenuText}>
                                                            {scheduleData.event_menu}
                                                        </Typography>
                                                    </Box>
                                                )
                                            } else {
                                                return ''
                                            }
                                        })()
                                    }
                                    <Typography
                                        sx={{ 
                                            lineHeight: 1.4, 
                                            fontSize: '15px',
                                            whiteSpace: 'normal',
                                        }}
                                    >
                                        {scheduleData.subject}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}} 
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >
                                    <EventNoteRoundedIcon sx={styles.boxAddInviteeIcon}/>
                                    <Typography sx={styles.label}>調整期間</Typography>
                                </Stack>
                                <Stack 
                                    direction={'row'}
                                    sx={{
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 130px)',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            lineHeight: 1.4,
                                            fontSize: '15px',
                                            whiteSpace: 'normal'
                                        }}
                                    >
                                        {formatTargetDatePeriod2(
                                            scheduleData.adjustment_start_date,
                                            scheduleData.adjustment_end_date,
                                        )}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}} 
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >
                                    <PeopleAltSharpIcon sx={styles.boxAddInviteeIcon}/>
                                    <Typography sx={styles.label}>参加者</Typography>
                                </Stack>
                                <Stack 
                                    direction={'row'}
                                    sx={{
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 130px)',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            lineHeight: 1.4, 
                                            fontSize: '15px',
                                            whiteSpace: 'normal'
                                        }}
                                    >
                                        {scheduleData.participant.map((e: any) => replaceGaroonName(e.user, width)).join('、')}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={'row'} spacing={{ xs: 1, xsm: 2}}>
                                <Stack 
                                    direction={'row'} 
                                    spacing={{ xs: 1, xsm: 2}} 
                                    sx={{
                                        width: {
                                            xs: "130px",
                                            xsm: "150px"
                                        },
                                    }}
                                >   
                                    <HelpCenterIcon styles={{fontSize: '21px', width: '1em', height: '1em'}}/>
                                    <Typography sx={styles.label}>未回答者</Typography>
                                </Stack>
                                <Stack 
                                    direction={'row'}
                                    sx={{
                                        marginLeft: 0,
                                        width: {
                                            xs: 'calc(100% - 130px)',
                                            xsm: 'calc(100% - 134px)',
                                        },
                                        display: {
                                            xs: 'flex'
                                        },
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'row'
                                        }
                                    }}
                                >
                                    <Stack 
                                        direction={'row'}
                                        sx={{
                                           
                                        }}
                                    >
                                    {notRepondentUsers !== null && notRepondentUsers !== undefined && notRepondentUsers.length > 0?
                                        <Typography
                                            sx={{
                                                lineHeight: 1.4, 
                                                fontSize: '15px',
                                                whiteSpace: 'normal'
                                            }}
                                        >
                                            {notRepondentUsers.map((e: any) => {
                                                return replaceGaroonName(e.user, width)
                                            }).join('、')}
                                        </Typography>
                                        :
                                        <>-</>
                                    }
                                    </Stack>
                                    {notRepondentUsers !== null && notRepondentUsers !== undefined && notRepondentUsers.length > 0 &&
                                    <Stack 
                                        direction={'row'}
                                        sx={{
                                            marginLeft: {
                                                xs: 0,
                                                sm: 5
                                            },
                                        }}
                                    >
                                        <Link
                                            style={{
                                                lineHeight: 1.4, 
                                                fontSize: '15px',
                                                whiteSpace: 'normal',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                minWidth: '170px'
                                            }}
                                            onClick={() => {setIsShowModalSendReminder(true)}}
                                        >リマインドメールを送信</Link>
                                    </Stack>
                                    }
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>

                    <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed', marginBottom: loadedTime ? '10px' : '24px' }}>
                        <Box sx={{ width: '100%', textAlign: 'right', verticalAlign: 'center' }}>
                            {garoonLink &&
                                <Link underline="none" color={'#0075FF'} sx={{ fontSize: '15px', lineHeight: '20px', fontWeight: '400', cursor: "pointer" }} href={garoonLink || '#'} target="_blank">
                                    <Box component={'span'} sx={{borderBottom: '1px solid #0075FF'}}>Garoonで見る</Box>
                                    <Box component={'span'} sx={{verticalAlign: 'bottom', paddingLeft: '2px', position: 'relative', top: '1px'}}><ExternalLinkIcon/></Box>
                                </Link>
                            }
                        </Box>
                        <TableContainer sx={styles.tableContainer}>
                            <Table sx={styles.table}>
                                <TableHead>
                                    <TableRow sx={{ border: 0 }}>
                                        <TableCell></TableCell>
                                        <TableCell>最終候補日時</TableCell>
                                        {scheduleData.participant.map((e: any, index: any) => {
                                            return (
                                                <TableCell key={e.id} align="center" sx={{position: 'relative'}}>                                    
                                                    {
                                                        e?.comment ? (
                                                            <Box>
                                                                <Tooltip 
                                                                    placement="top" 
                                                                    title={e?.comment || ''}
                                                                    onOpen={() => {
                                                                        // hiden comment icon when Tooltip is shown
                                                                        let commentIcon = document.getElementById(`comment-icon-${index}`);          
                                                                        if (commentIcon) {
                                                                            commentIcon.style.display = 'none';
                                                                        }
                                                                    }}
                                                                    onClose={() => {
                                                                        // show comment icon when Tooltip is hidden
                                                                        let commentIcon = document.getElementById(`comment-icon-${index}`);          
                                                                        if (commentIcon) {
                                                                            commentIcon.style.display = 'block';
                                                                        }
                                                                    }}
                                                                    arrow
                                                                    componentsProps={{
                                                                        tooltip: {
                                                                            sx: {
                                                                                backgroundColor: '#fff',
                                                                                color: '#000',
                                                                                border: '1px solid #ff943d',
                                                                                borderRadius: '8px',
                                                                                padding: '10px 16px 10px 16px',
                                                                                fontSize: '12px',
                                                                                whiteSpace: 'pre-line',
                                                                            },
                                                                        },
                                                                        arrow: {
                                                                            sx: {
                                                                                color: "#fff",
                                                                                fontSize: '16px',
                                                                                "&::before": {
                                                                                    border: "1px solid #ff943d",
                                                                                },
                                                                            }
                                                                        }
                                                                    }}
                                                                >
                                                                    <div style={{cursor: 'pointer'}}>
                                                                        {limitCharacter(replaceGaroonName(e.user, width), 5)}
                                                                    </div>
                                                                </Tooltip>
                                                                <div
                                                                    id={`comment-icon-${index}`}
                                                                >
                                                                    <CommentIcon
                                                                        sx={{ 
                                                                            fontSize: '24px', 
                                                                            color: '#ff943d',
                                                                            position: 'absolute',
                                                                            top: '-10px',
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Box>
                                                        ) : (
                                                            limitCharacter(replaceGaroonName(e.user, width), 5)
                                                        )
                                                    }
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(adjustmentData) && adjustmentData.map((row: any, index: number) => {
                                        let rowPoint = 0
                                        return (
                                            <TableRow
                                                key={row.id}
                                                tabIndex={0}
                                                hover
                                                onClick={() => setIdxChecked(index)}
                                            >
                                                <TableCell 
                                                    size="small" 
                                                    sx={{ 
                                                        verticalAlign: 'middle',
                                                        padding: {
                                                            sm: '8px 16px !important',
                                                            xs: '0px !important',
                                                        },
                                                    }}
                                                >
                                                    <Checkbox
                                                        sx={{
                                                            color: '#000',
                                                        }}
                                                        checked={index === idxChecked}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        fontWeight: '400',
                                                        fontSize: '16px',
                                                        verticalAlign: 'middle',
                                                        padding: {
                                                            sm: '8px 16px !important',
                                                            xs: '8px 6px !important',
                                                        },
                                                        lineHeight: {
                                                            xs: '1.2em',
                                                            sm: '30px',
                                                        },
                                                        minWidth: {
                                                            xs: '130px',
                                                            sm: '260px',
                                                        },
                                                    }}
                                                >
                                                    { row.duration !== 1439 
                                                        ? (<>
                                                            {dayjs(row.start_time).format(
                                                                `YYYY/M/D(${
                                                                    CONSTANTS.DAY_OF_WEEK[dayjs(row.start_time).day()]
                                                                }) H:mm`,
                                                            )}
                                                            {`〜 `}
                                                            {row.duration !== 1439 ? `(${row.duration}分)` : '23:59'}
                                                        </>)
                                                        : (<>
                                                            {dayjs(row.start_time).format(
                                                                `YYYY/M/D(${
                                                                    CONSTANTS.DAY_OF_WEEK[dayjs(row.start_time).day()]
                                                                })`,
                                                            )}
                                                        </>)
                                                    }
                                                </TableCell>
                                                {scheduleData.participant.map((el: any, i: number) => {
                                                    const adjustment = row.adjustmentParticipant.find((e: any) => {
                                                        return e.participant.user_id === el.user_id
                                                    })

                                                    const userSchedule = adjustment?.schedule_garoon;                                                  
                                                    let status = adjustment?.status >= 0 ? adjustment?.status : 5;
                                                    if ((!userSchedule || userSchedule?.length === 0) && adjustment?.participant?.user?.is_employee) {
                                                        status = 5;
                                                    }

                                                    if (!adjustment?.participant) {
                                                        status = undefined;
                                                    }

                                                    if (status >= 0) {
                                                        rowPoint += status
                                                        if (i === scheduleData.participant.length - 1) {
                                                            if (maxPoint.current < rowPoint) {
                                                                maxPoint.current = rowPoint
                                                                setIdxChecked(index)
                                                            }
                                                        }
                                                        return (
                                                            <TableCell
                                                                key={el.user_id}
                                                                sx={{
                                                                    fontSize: '24px',
                                                                    position: 'relative',
                                                                    padding: {
                                                                        sm: '8px 16px !important',
                                                                        xs: '0px 6px !important',
                                                                    }
                                                                }}
                                                            >
                                                                {
                                                                    userSchedule !== undefined && userSchedule.length > 0 ? (
                                                                        <Tooltip
                                                                            title={
                                                                                <>
                                                                                    <Box
                                                                                        className='swiper-button swiper-button-next'
                                                                                        sx={{
                                                                                            ':after' :{
                                                                                                fontSize: '20px',
                                                                                            },
                                                                                            right: 0
                                                                                        }}
                                                                                        onClick={(e) => {e.stopPropagation()}}
                                                                                    >
                                                                                    </Box>
                                                                                    <Box
                                                                                        className='swiper-button swiper-button-prev'
                                                                                        sx={{
                                                                                            ':after' :{
                                                                                                fontSize: '20px',
                                                                                            },
                                                                                            left: 0
                                                                                        }}
                                                                                        onClick={(e) => {e.stopPropagation()}}
                                                                                    >
                                                                                    </Box>
                                                                                    <Swiper
                                                                                        modules={[Navigation, Scrollbar]}
                                                                                        spaceBetween={50}
                                                                                        slidesPerView={'auto'}
                                                                                        navigation={{
                                                                                            nextEl: '.swiper-button-next',
                                                                                            prevEl: '.swiper-button-prev',
                                                                                            disabledClass: 'swiper-button-disabled',
                                                                                        }}
                                                                                        className='mySwiper'
                                                                                    >
                                                                                    {userSchedule.map((el: any, j: number) => {
                                                                                        return (
                                                                                            <SwiperSlide 
                                                                                                key={j} 
                                                                                                onClick={(e) => {e.stopPropagation()}}
                                                                                            >
                                                                                                <Box className='swich-compo column'>
                                                                                                    <Stack spacing={0} sx={{gap: '3px'}} direction={'column'}>
                                                                                                        <Typography sx={{ fontSize: '15px', fontWeight: '500' }}>
                                                                                                            {el?.start?.dateTime ? moment(el?.start?.dateTime, 'YYYY/MM/DD HH:mm').format('HH:mm') : ''} - {el?.end?.dateTime ? moment(el?.end?.dateTime, 'YYYY/MM/DD HH:mm').format('HH:mm') : ''}
                                                                                                        </Typography>
                                                                                                        {el?.event_menu &&
                                                                                                        <Typography component={'div'}>
                                                                                                            <Chip size="small" sx={{fontSize: '13px', lineHeight: '20px', fontWeight: '500', minWidth: '80px', color: '#ffffff'}} color="primary" label={el?.event_menu} />
                                                                                                        </Typography>
                                                                                                        }
                                                                                                        {el?.visibility === 'PRIVATE' && el?.subject === 'Private appointment' 
                                                                                                            ?   <Typography sx={{fontSize: '15px', fontWeight: '500'}}>{'予定あり'}</Typography>
                                                                                                            :   <Link
                                                                                                                    sx={{fontSize: '15px', fontWeight: '500'}}
                                                                                                                    href={el?.link || '#'}
                                                                                                                    target="_blank"
                                                                                                                >
                                                                                                                    {el?.subject || '詳細を見る'} 
                                                                                                                </Link>
                                                                                                        }
                                                                                                    </Stack>
                                                                                                </Box>
                                                                                            </SwiperSlide>
                                                                                        )
                                                                                    })}
                                                                                    </Swiper>
                                                                                </>
                                                                            }
                                                                            placement="top"
                                                                            arrow
                                                                            componentsProps={{
                                                                                tooltip: {
                                                                                    sx: {
                                                                                        backgroundColor: '#fff',
                                                                                        color: '#000',
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '8px',
                                                                                        padding: userSchedule.length > 1 ? '10px 30px 10px 30px' : '10px 16px 10px 16px',
                                                                                        fontSize: '12px',
                                                                                        whiteSpace: 'pre-line',
                                                                                        minWidth: '200px',
                                                                                        maxWidth: '250px'
                                                                                    },
                                                                                },
                                                                                arrow: {
                                                                                    sx: {
                                                                                        color: "#fff",
                                                                                        fontSize: '18px',
                                                                                        "&::before": {
                                                                                            border: "1px solid #000",
                                                                                        },
                                                                                    }
                                                                                },
                                                                                popper: {
                                                                                    sx: {
                                                                                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                                                                            {
                                                                                                marginTop: '0px',
                                                                                            },
                                                                                        [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                                                                            {
                                                                                                marginBottom: '20px',
                                                                                            },
                                                                                        [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                                                                                            {
                                                                                                marginLeft: '0px',
                                                                                            },
                                                                                        [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                                                                                            {
                                                                                                marginRight: '0px',
                                                                                            },
                                                                                    },
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <LightFieldByPoint
                                                                                    point={status}
                                                                                    fill="black"
                                                                                />
                                                                            </div>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <LightFieldByPoint
                                                                            point={status}
                                                                            fill="black"
                                                                        />
                                                                    )
                                                                }
                                                            </TableCell>
                                                        )
                                                    } 
                                                    if (!hasUserNotConfirm) {
                                                        setHasUserNotConfirm(true)
                                                    }
                                                    if (i === scheduleData.participant.length - 1) {
                                                        if (maxPoint.current < rowPoint) {
                                                            maxPoint.current = rowPoint
                                                            setIdxChecked(index)
                                                        }
                                                    }
                                                    return (
                                                        <TableCell
                                                            key={el.user_id}
                                                            align="center"
                                                            sx={{
                                                                fontSize: '24px',
                                                                alignItems: 'center',
                                                                position: 'relative',
                                                            }}
                                                        >
                                                            <LightFieldByPoint point={-1} fill="#000" />
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    {loadedTime &&
                        <Grid 
                            sx={{
                                width: '100%',
                                textAlign: {
                                    xs: 'left',
                                    sm: 'right'
                                },
                                marginBottom: {
                                    xs: '0px'
                                },
                                fontSize: '14px'
                            }}
                        >
                            ※{loadedTime}時点の予定です
                        </Grid>
                    }
                    <Grid
                        xs={12}
                        sx={{ display: 'flex', marginTop: '40px', width: '100%' }}
                        justifyContent={'space-between'}
                    >
                        <ButtonCustom sx={{fontSize: {sm: '15px', xs: '13px'}}} child={'一覧に戻る'} onClick={() => navigate('/schedule-list')} />
                        <ButtonCustom
                            child={'施設選択'}
                            onClick={() => {
                                if (idxChecked !== -1) {
                                    reset()
                                    setFileAttachment([])
                                    setFilesData([])
                                    setErrorFile('')
                                    if (hasUserNotConfirm) {
                                        setOpenModalConfirm(true);
                                    } else {
                                        // setIsShowModal(true)
                                        nextStep2()
                                    }
                                }
                            }}
                            sx={{ minWidth: '160px !important', fontSize: {sm: '15px', xs: '13px'}}}
                            isSubmit
                            endIcon={<ArrowForwardIosIcon sx={{ fontSize: '14px !important' }} />}
                        />
                    </Grid>
                    {idxChecked !== -1 && (
                        <>
                            <ModalConfirm
                                openModalConfirm={openModalConfirm}
                                isLoading={isLoading}
                                closeModalConfirm={() => {setOpenModalConfirm(false)}}
                                onConfirm={() => {
                                    setOpenModalConfirm(false)
                                    // setIsShowModal(true);
                                    nextStep2()
                                }}
                                title={'確認'}
                                message={'未回答の招待者がいますが、最終調整を進めてもよろしいですか？'}
                                btnLeft='戻る'
                                btnRight='はい'
                            />
                        </>
                    )}

                    <Modal 
                        open={isShowModalSendReminder} 
                        onClose={() => {
                            setIsShowModalSendReminder(false)
                            reset()
                            setFileAttachment([])
                            setFilesData([])
                            setErrorFile('')
                        }} 
                        sx={{ overflow: 'auto', display: "flex" }}
                    >
                        <Box sx={styles.modalBoxWrapper}>
                            <Grid container xs={12}>
                                <Grid xs={12}>
                                    <Stack spacing={1} sx={{ mt: 3 }}>
                                        <Typography sx={{ ...labelOfInput, ...labelOfInputAlign }}>件名</Typography>
                                        <Controller
                                            name="email_subject"
                                            control={control}
                                            defaultValue={remindEmailSubject}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <>
                                                    <TextInput
                                                        onChange={(event: any) => {
                                                            onChange(event.target.value.toString())
                                                        }}
                                                        value={value}
                                                    />
                                                    {error ? (
                                                        <FormHelperText error> {error.message} </FormHelperText>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid xs={12}>
                                    <Stack spacing={1} sx={{ mt: 3 }}>
                                        <Typography sx={{ ...labelOfInput, ...labelOfInputAlign }}>本文</Typography>
                                        <Controller
                                            name="email_body"
                                            control={control}
                                            defaultValue={remindEmailBody}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <>
                                                    <Typography 
                                                        component={'div'} 
                                                        sx={{
                                                            marginTop: '8px',
                                                            border: '1px solid #666',
                                                            borderRadius: '15px',
                                                            fontSize: '15px',
                                                        }}
                                                    >
                                                        <CKEditor
                                                            editor={DecoupledEditor}
                                                            data={value}
                                                            config={{
                                                                removePlugins: ['Title'],
                                                                toolbar: [ 'undo', 'redo', '|', 'bold', 'fontColor' ],
                                                                placeholder: '',
                                                                translations: {
                                                                    '': {
                                                                        dictionary: {
                                                                            'Remove color': '色を削除',
                                                                            'Color picker': 'その他の色',
                                                                            'Document colors': '選択された色',
                                                                        },
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(event: any, editor: any) => {
                                                                const data = editor.getData()
                                                                onChange(data)
                                                            }}
                                                            onReady={(editor: any) => {
                                                                editor?.ui?.getEditableElement()?.parentElement?.insertBefore(
                                                                    editor.ui.view.toolbar.element as Node,
                                                                    editor.ui.getEditableElement() as Node
                                                                );

                                                                editor.editing.view.change((writer: any) => {
                                                                    writer.setStyle({
                                                                        height: '260px',
                                                                    }, editor.editing.view.document.getRoot())
                                                                })
                                                            }}
                                                        />
                                                    </Typography>
                                                    {error ? (
                                                        <FormHelperText
                                                            error
                                                            sx={{ position: 'absolute', bottom: '-20px' }}
                                                        >
                                                            {error.message}
                                                        </FormHelperText>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid xs={12} sx={{ mt: 3, mb: '40px'}}>
                                    {componentAttachmentFile()}
                                </Grid>
                            </Grid>
                            
                            <Grid xs={12}>
                                <Box sx={styles.footerModal}>
                                    <ButtonCustom 
                                        child={'戻る'} 
                                        onClick={() => {
                                            setIsShowModalSendReminder(false)
                                            reset()
                                            setFileAttachment([])
                                            setFilesData([])
                                            setErrorFile('')
                                        }} 
                                        sx={{ 
                                            fontSize: {
                                                xs: '12px',
                                                sm: '15px',
                                            },
                                        }}
                                    />
                                    <ButtonCustom
                                        isSubmit
                                        sx={{
                                            minWidth: '186px !important',
                                            fontSize: {
                                                xs: '12px',
                                                sm: '15px',
                                            },
                                        }}
                                        child={'送信する'}
                                        onClick={handleSubmit(handleFormSubmitRemindMail)}
                                        onMouseDown={() => {
                                            if (
                                                getValues('email_subject').length === 0 ||
                                                getValues('email_subject').length > 100 ||
                                                getValues('email_body').length === 0 ||
                                                getValues('email_body').length > 5000
                                            ) {
                                                showSnackBar(
                                                    true,
                                                    '入力エラーがあります。修正して再度実行ボタンをクリックしてください。',
                                                    'error',
                                                )
                                            }
                                        }}
                                        disabled={isLoading}
                                    />
                                </Box>
                            </Grid>
                        </Box>
                    </Modal>
                </Grid>
            </Box>
            </Paper>
        </Container>
    )
}

export default FinalizeStep1

const styles = {
    paper: {
        p: { xs: '20px', sm: '30px', md: 5 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: ' 0px 4px 120px rgba(0, 0, 0, 0.25)',
        color: '#000000',
        marginTop: '28px',
        borderRadius: '20px',
        border: '1px solid transparent',
    },
    label: {
        lineHeight: 1.4,
        fontSize: '15px',
        height: '21px',
        margin: 'auto 0',
    },
    container: {
        alignSelf: 'center',
    },
    title: {
        fontWeight: '500',
        fontSize: '15px',
        alignItem: 'center',
        whiteSpace: 'nowrap',
        height: '21px',
        margin: 'auto 0',
    },
    adjustmentTitle: {
        pb: '20px',
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
        height: '100%',
        whiteSpace: 'nowrap',
        borderRadius: '20px',
    },
    adjustmentInfo: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '7px',
        paddingLeft: {
            xs: '0px',
            sm: '20px',
        },
    },
    boxScheduleEventMenu: {
        color: '#FFF',
        borderRadius: '10px',
        p: '0px 10px',
        display: 'flex',
        alignItems: 'center',
    },
    boxScheduleEventMenuText: {
        fontSize: '12px',
        fontWeight: '500',
    },
    boxAddInvitee: {
        display: 'flex',
        cursor: 'pointer',
        gap: '8px',
    },
    boxAddInviteeIcon: {
        fontSize: '21px',
    },
    tableContainer: {
        pt: '20px',
    },
    table: {
        th: {
            ...tableHeaderText,
            ...tableRowAlign,
            borderBottom: '1px solid #666666',
        },
        'tbody > tr': {
            cursor: 'pointer',
        },
        td: {
            ...tableBodyText,
            ...tableRowAlign,
            borderBottom: '1px solid #56577A',
        },
        marginBottom: 0,
    },
    modalBoxWrapper: {
        maxHeight: '90vh',
        overflowY: 'auto',
        p: { xs: '20px', sm: '30px', md: 5 },
        borderRadius: '16px',
        width: '700px',
        bgcolor: '#fff',
        margin: 'auto',
        border: 'none',
        '@media (max-width: 768px)': {
            width: '90%',
        },
    },
    footerModal: { display: 'flex', justifyContent: 'space-between', flexDirection: 'row' },
}
