import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Meta } from '../../models'
import dayjs from 'dayjs'
import { CONSTANTS } from 'src/config/constants'
import { set } from 'lodash'

export interface TopState {
    //step 1
    organizations: any[]
    loadingOrganization: boolean
    filterOrganization: {}
    metaOrganization: Meta

    internalUsers: any[]
    loadingInternalUsers: boolean
    filterInternalUsers: any
    metaInternalUsers: Meta

    facilityGroups: any[]
    loadingFacilityGroup: boolean
    filterFacilityGroup: {}
    metaFacilityGroup: Meta

    facilities: any[]
    loadingFacilities: boolean
    filterFacilities: any
    metaFacilities: Meta

    facilitiesInUsed: any[]
    loadingFacilitiesInUsed: boolean

    loadingFetchScheduleById: boolean

    infoSchedule: {
        user: any
        subject: string
        // garoon_subject: string
        event_type: string
        event_menu: string
        participants: any[]
        adjustment_start_date: any
        adjustment_end_date: any
        status: string,
        facilities: any[],
        note: string,
        adjustment_period?: number,
        condition_of_internal_user?: string,
        period_type?: number,
    }
    loadingCreateSchedule: boolean
    scheduleCreatedData: {}
    loadingUpdateSchedule: boolean

    loadingSchedule: boolean

    //step 3
    externalUsers: any[]
    loadingExternalUsers: boolean
    filterExternalUsers: {}
    metaExternalUsers: Meta

    // Error handling
    showAlert: boolean
    alertMessage: string
    alertSeverity: string

    eventType: number
}

const defaultOrganizations: any = [
    {
        id: 0,
        name: '全社員',
    },
]
const defaultFacilityGroups: any = [
    {
        garoon_group_id: 0,
        name: '全施設',
    },
]

const initialState: TopState = {
    organizations: [...defaultOrganizations],
    loadingOrganization: false,
    filterOrganization: {},
    metaOrganization: {
        pageIndex: 1,
        pageSize: 10,
        totalItems: 10,
        totalPage: 4,
        hasPreviousPage: false,
        hasNextPage: false,
    },

    internalUsers: [],
    loadingInternalUsers: false,
    filterInternalUsers: {
        organization_id: 0,
        text: '',
    },
    metaInternalUsers: {
        pageIndex: 1,
        pageSize: 10,
        totalItems: 10,
        totalPage: 4,
        hasPreviousPage: false,
        hasNextPage: false,
    },

    facilityGroups: [...defaultFacilityGroups],
    loadingFacilityGroup: false,
    filterFacilityGroup: {},
    metaFacilityGroup: {
        pageIndex: 1,
        pageSize: 10,
        totalItems: 10,
        totalPage: 4,
        hasPreviousPage: false,
        hasNextPage: false,
    },

    facilities: [],
    loadingFacilities: false,
    filterFacilities: {
        groupId: 0,
        text: '',
    },
    metaFacilities: {
        pageIndex: 1,
        pageSize: 10,
        totalItems: 10,
        totalPage: 4,
        hasPreviousPage: false,
        hasNextPage: false,
    },

    facilitiesInUsed: [],
    loadingFacilitiesInUsed: false,

    loadingFetchScheduleById: true,

    infoSchedule: {
        user: {},
        subject: '',
        // garoon_subject: '',
        event_type: '',
        event_menu: '---',
        participants: [],
        adjustment_start_date: undefined,
        adjustment_end_date: undefined,
        status: '',
        facilities: [],
        note: '',
        adjustment_period: 3,
        condition_of_internal_user: 'or',
        period_type: CONSTANTS.PERIOD_TYPE.FIXED,
    },
    loadingCreateSchedule: true,
    loadingUpdateSchedule: true,

    loadingSchedule: false,

    scheduleCreatedData: {},

    //step 3
    externalUsers: [],
    loadingExternalUsers: false,
    filterExternalUsers: {},
    metaExternalUsers: {
        pageIndex: 1,
        pageSize: 10,
        totalItems: 10,
        totalPage: 4,
        hasPreviousPage: false,
        hasNextPage: false,
    },

    showAlert: false,
    alertMessage: '',
    alertSeverity: 'success',

    eventType: parseInt(localStorage.getItem('event-type') || CONSTANTS.TYPE_ADJUSTMENT.INTERNAL.toString()),
}

const adjustmentSlice = createSlice({
    name: 'adjustment',
    initialState,
    reducers: {
        //step 1 - update
        fetchScheduleById(state, action) {
            state.loadingFetchScheduleById = true
        },
        fetchScheduleSuccess(state, action) {
            state.infoSchedule = {
                ...state.infoSchedule,
                user: action.payload.data.user,
                participants: action.payload.data.participant
                    .filter((el: any) => el.user.is_employee)
                    .map((item: any) => item.user),
                subject: action.payload.data.subject,
                // garoon_subject: action.payload.data.garoon_subject,
                event_type: action.payload.data.event_type,
                event_menu: action.payload.data.event_menu ? action.payload.data.event_menu : "---",
                adjustment_start_date: action.payload.data.adjustment_start_date ? dayjs(action.payload.data.adjustment_start_date) : undefined,
                adjustment_end_date: action.payload.data.adjustment_end_date ? dayjs(action.payload.data.adjustment_end_date) : undefined,
                status: action.payload.data.status,
                facilities: action.payload.data?.facility ? [...action.payload.data?.facility] : [],
                note: action.payload.data?.note ? action.payload.data?.note : '',
                adjustment_period: action.payload.data?.adjustment_period ? action.payload.data?.adjustment_period : 3,
                condition_of_internal_user: action.payload.data?.condition_of_internal_user ? action.payload.data?.condition_of_internal_user : 'or',
                period_type: action.payload.data?.period_type ? action.payload.data?.period_type : CONSTANTS.PERIOD_TYPE.FIXED,
            }
            state.loadingFetchScheduleById = false
        },
        //step 1 - create
        // organizations
        fetchOrganizations(state) {
            state.loadingOrganization = true
        },
        fetchOrganizationsSuccess(state, action) {
            state.organizations = [...defaultOrganizations, ...action.payload.data]
            state.filterInternalUsers.organization_id = action.payload.data[0].name === CONSTANTS.ORGANIZATION_ALL.NAME ? action.payload.data[0].id : defaultOrganizations[0].id
            state.metaOrganization = action.payload.meta
            state.loadingOrganization = false
        },
        fetchOrganizationsFailed(state) {
            state.loadingOrganization = false
        },
        setOrganizations(state, action) {
            state.organizations = action.payload
        },

        //internal users
        fetchInternalUsers(state) {
            state.loadingInternalUsers = true
        },
        fetchInternalUsersSuccess(state, action) {
            state.internalUsers = [...action.payload.data]
            state.metaInternalUsers = action.payload.meta
            state.loadingInternalUsers = false
        },
        fetchInternalUsersFailed(state) {
            state.internalUsers = []
            state.loadingInternalUsers = false
        },
        setInternalUsers(state, action) {
            state.internalUsers = action.payload
        },
        setFilterInternalUser(state, action) {
            state.filterInternalUsers = action.payload
        },

        // facility groups
        fetchFacilityGroups(state) {
            state.loadingFacilityGroup = true
        },
        fetchFacilityGroupsSuccess(state, action) {
            state.facilityGroups = [...defaultFacilityGroups, ...action.payload.data]
            state.metaFacilityGroup = action.payload.meta
            state.loadingFacilityGroup = false
        },
        fetchFacilityGroupsFailed(state) {
            state.loadingFacilityGroup = false
        },
        setFacilityGroups(state, action) {
            state.facilityGroups = action.payload
        },

        // facility in used
        fetchFacilitiesInUsed(state, action) {
            state.loadingFacilitiesInUsed = true
        },
        fetchFacilitiesInUsedSuccess(state, action) {
            state.facilitiesInUsed = [...action.payload]
            state.loadingFacilitiesInUsed = false
        },
        fetchFacilitiesInUsedFailed(state) {
            state.loadingFacilitiesInUsed = false
        },
        setFacilitiesInUsed(state, action) {
            state.facilitiesInUsed = action.payload
        },

        // facility
        fetchFacilities(state) {
            state.loadingFacilities = true
        },
        fetchFacilitiesSuccess(state, action) {
            state.facilities = [...action.payload.data]
            state.metaFacilities = action.payload.meta
            state.loadingFacilities = false
        },
        fetchFacilitiesFailed(state) {
            state.facilities = []
            state.loadingFacilities = false
        },
        setFacilities(state, action) {
            state.facilities = action.payload
        },
        setFilterFacility(state, action) {
            state.filterFacilities = action.payload
        },

        //schedule
        createSchedule(state, action) {
            state.loadingCreateSchedule = true
            state.showAlert = false;
            state.alertMessage = '';
            state.loadingSchedule = true
        },
        createScheduleSuccess(state, action) {
            state.scheduleCreatedData = action.payload.data
            state.loadingCreateSchedule = false
            state.loadingSchedule = false
        },
        createScheduleFailed(state) {
            state.loadingCreateSchedule = false
            state.showAlert = true;
            state.alertMessage = 'エラーが発生しました。管理者にお問合せください。';
            state.alertSeverity = 'error';
            state.loadingSchedule = false
        },
        setLoadingCreateSchedule(state, action) {
            state.loadingCreateSchedule = action.payload
        },
        setLoadingSchedule(state, action) {
            state.loadingSchedule = action.payload
        },
        updateSchedule(state, action) {
            state.loadingUpdateSchedule = true
            state.showAlert = false;
            state.alertMessage = '';
            state.loadingSchedule = true
        },
        updateScheduleSuccess(state) {
            state.loadingUpdateSchedule = false
            state.loadingSchedule = false
        },
        updateScheduleFailed(state) {
            state.loadingUpdateSchedule = false
            state.showAlert = true;
            state.alertMessage = 'エラーが発生しました。管理者にお問合せください。';
            state.alertSeverity = 'error';
            state.loadingSchedule = false
        },
        setLoadingUpdateSchedule(state, action) {
            state.loadingUpdateSchedule = action.payload
        },
        setInfoSchedule(state, action) {
            state.infoSchedule = action.payload
        },
        fetchInfoSchedule(state, action) {},
        fetchInfoScheduleSuccess(state, action) {},
        fetchInfoScheduleFailed(state) {},

        //step 3
        fetchExternalUsers(state, action) {
            state.loadingExternalUsers = true
        },
        fetchExternalUsersSuccess(state, action) {
            state.externalUsers = action.payload.data
            state.metaExternalUsers = action.payload.data
            state.loadingExternalUsers = false
        },
        fetchExternalUsersFailed(state) {
            state.loadingExternalUsers = false
        },
        setExternalUsers(state, action) {
            state.externalUsers = action.payload
        },
        setFilterExternalUsers(state, action) {
            state.filterExternalUsers = action.payload
        },
        cancelHideAlert(state) {
            state.showAlert = false
            state.alertMessage = ''
        },
        setEventType(state, action) {
            state.eventType = action.payload
        }
    },
})

// actions
export const adjustmentActions = adjustmentSlice.actions

// selectors
//step 1
export const selectOrganizations = (state: any) => state.adjustment.organizations
export const selectFilterOrganization = (state: any) => state.adjustment.filterOrganization
export const selectLoadingOrganization = (state: any) => state.adjustment.loadingOrganization
export const selectMetaOrganization = (state: any) => state.adjustment.metaOrganization
export const selectLoadingFetchScheduleById = (state: any) => state.adjustment.loadingFetchScheduleById

export const selectInternalUsers = (state: any) => state.adjustment.internalUsers
export const selectFilterInternalUsers = (state: any) => state.adjustment.filterInternalUsers
export const selectLoadingInternalUsers = (state: any) => state.adjustment.loadingInternalUsers
export const selectMetaInternalUsers = (state: any) => state.adjustment.metaInternalUsers

export const selectFacilityGroups = (state: any) => state.adjustment.facilityGroups
export const selectFilterFacilityGroup = (state: any) => state.adjustment.filterFacilityGroup
export const selectLoadingFacilityGroup = (state: any) => state.adjustment.loadingFacilityGroup
export const selectMetaFacilityGroup = (state: any) => state.adjustment.metaFacilityGroup

export const selectFacilitiesInUsed = (state: any) => state.adjustment.facilitiesInUsed
export const selectFilterFacilitiesInUsed = (state: any) => state.adjustment.filterFacilitiesInUsed
export const selectLoadingFacilitiesInUsed = (state: any) => state.adjustment.loadingFacilitiesInUsed

export const selectFacilities = (state: any) => state.adjustment.facilities
export const selectFilterFacilities = (state: any) => state.adjustment.filterFacilities
export const selectLoadingFacilities = (state: any) => state.adjustment.loadingFacilities
export const selectMetaFacilities = (state: any) => state.adjustment.metaFacilities

export const selectInfoSchedule = (state: any) => state.adjustment.infoSchedule
export const selectloadingCreateSchedule = (state: any) => state.adjustment.loadingCreateSchedule
export const selectScheduleCreatedData = (state: any) => state.adjustment.scheduleCreatedData
export const selectLoadingUpdateSchedule = (state: any) => state.adjustment.loadingUpdateSchedule
export const selectLoadingSchedule = (state: any) => state.adjustment.loadingSchedule

//step 3
export const selectExternalUsers = (state: any) => state.adjustment.externalUsers
export const selectFilterExternalUsers = (state: any) => state.adjustment.filterExternalUsers
export const selectLoadingExternalUsers = (state: any) => state.adjustment.loadingExternalUsers
export const selectMetaExternalUsers = (state: any) => state.adjustment.metaExternalUsers

export const selectAlertMessage = (state: any) => state.adjustment.alertMessage
export const selectAlertSeverity = (state: any) => state.adjustment.alertSeverity
export const selectShowAlert = (state: any) => state.adjustment.showAlert

export const selectEventType = (state: any) => state.adjustment.eventType
// reducer
const adjustmentReducer = adjustmentSlice.reducer
export default adjustmentReducer
