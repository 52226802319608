import { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { IoDocument, IoBuild } from 'react-icons/io5'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ListItemCustom from './ListItemCustom'
import theme from 'src/theme'
import { HomeIconComponent } from 'src/components/Icons'
import { checkRoute } from '../../helpers/checkRouteName'
import GroupIcon from '@mui/icons-material/Group';

export interface ListItemSideMenuProps {
    open: boolean
    setOpenMobile?: (open: boolean) => void
}

const ListItemSideMenu = ({ open, setOpenMobile }: ListItemSideMenuProps) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const [colorIcon, setColorIcon] = useState('#fff')
    const [listItem, setListItem] = useState([
        {
            name: '予定調整',
            path: '',
            icon: <IoDocument size="15px" style={{ color: colorIcon }} />,
            chevron: true,
            listSubItem: [
                {
                    name: '新規調整',
                    path: '/adjustment/init',
                },
                {
                    name: '調整一覧',
                    path: '/schedule-list',
                },
            ],
        },
    ])

    const [listItem2, setListItem2] = useState([
        {
            name: '設定',
            path: '',
            icon: <IoBuild size="15px" style={{ color: colorIcon }} />,
            chevron: false,
            listSubItem: [],
        },
    ])

    const [listGroupItem, setListGroupItem] = useState([
        {
            name: 'Myグループ',
            path: '',
            icon: <GroupIcon style={{ color: colorIcon, fontSize: '15px' }} />,
            chevron: true,
            listSubItem: [
                {
                    name: 'Myグループ作成',
                    path: '/group/member-manage',
                },
                {
                    name: 'Myグループ一覧',
                    path: '/group/list',
                },
            ],
        },
    ])

    const handlenavigate = (route: string) => {
        navigate(route)
    }

    return (
        <List
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
                gap: '20px',
            }}
        >
            <ListItem
                disablePadding
                sx={{
                    display: 'block',
                    width: open ? '90%' : '',
                    borderRadius: '15px',
                    background:
                        open && checkRoute(pathname) === ''
                            ? 'linear-gradient(90deg, #444444 0%, rgba(68, 68, 68, 0) 99.77%)'
                            : 'transparent',
                    filter: open ? 'drop-shadow(0px 3.5px 5.5px rgba(0, 0, 0, 0.02))' : 'none',
                }}
                onClick={() => handlenavigate('/top')}
            >
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        p: '12px 16px',
                    }}
                    onClick={() => {
                        setOpenMobile && setOpenMobile(!open)
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 2 : 'auto',
                            justifyContent: 'center',
                            padding: open ? '10px' : '0px',
                            borderRadius: open ? '12px' : '0px',
                            backgroundColor: open
                                ? checkRoute(pathname) === ''
                                    ? theme.palette.secondary.main
                                    : 'rgba(245,245,245, 0.2)'
                                : 'transparent',
                        }}
                    >
                        {/* <IoHome size="15px" color={open ? "#000" : "#fff"} /> */}
                        <HomeIconComponent fill={open ? (checkRoute(pathname) === '' ? '#000' : '#fff') : '#fff'} />
                    </ListItemIcon>
                    <Link style={{ textDecoration: 'none' }} to="/top">
                        <ListItemText
                            primary={'TOP'}
                            sx={{
                                '.MuiTypography-root': {
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                    lineHeight: '15px',
                                },
                                opacity: open ? 1 : 0,
                                color: '#fff',
                            }}
                        />
                    </Link>
                </ListItemButton>
            </ListItem>
            <ListItem
                disablePadding
                sx={{
                    display: open ? 'block' : 'none',
                    width: open ? '90%' : '',
                    borderRadius: '12px',
                    backgroundColor: 'transparent',
                    px: 2,
                }}
            >
                <ListItemText
                    primary={'MENU'}
                    sx={{
                        '.MuiTypography-root': {
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '18px',
                        },
                        opacity: open ? 1 : 0,
                        color: '#fff',
                    }}
                />
            </ListItem>
            <ListItemCustom
                listItem={listItem}
                open={open}
                setOpenMobile={setOpenMobile}
                handlenavigate={handlenavigate}
            />
            {/* Temporarily hidden */}
            {/* <ListItemCustom
                listItem={listItem2}
                open={open}
                setOpenMobile={setOpenMobile}
                handlenavigate={handlenavigate}
            /> */}
            <ListItemCustom
                listItem={listGroupItem}
                open={open}
                setOpenMobile={setOpenMobile}
                handlenavigate={handlenavigate}
            />
        </List>
    )
}

export default ListItemSideMenu
