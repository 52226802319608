import { get, post} from './axiosClient'

const externalUserApi = {
    validateHash(hash: string): any {
        const url = '/external-user/validate/' + hash 
        return get(url)
    },

    validateHashSingleExternal(hash: string): any {
        const url = '/external-user/single-external-validate/' + hash 
        return get(url)
    },

    confirm(hash: string, data: any = {}): any {
        const url = '/external-user/confirm/' + hash
        return post(url, data)
    },

    sharedUrlConfirm(hash: string, data: any = {}): any {
        const url = '/external-user/share-url-confirm/' + hash
        return post(url, data)
    },

    singleExternalConfirm(hash: string, data: any = {}): any {
        const url = '/external-user/single-external-confirm/' + hash
        return post(url, data)
    },

    sharedUrlSingleExternalConfirm(hash: string, data: any = {}): any {
        const url = '/external-user/share-url-single-external-confirm/' + hash
        return post(url, data)
    },
    
    sharedUrlSingleExternalFluidConfirm(hash: string, data: any = {}): any {
        const url = '/external-user/share-url-single-external-fluid-confirm/' + hash
        return post(url, data)
    },
}

export default externalUserApi
